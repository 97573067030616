<!-- <div class="push"></div> -->
<footer>
    <div class="footer-copyright">
        <div class="container halign left copyright">
            <div class="wrapper">
                <span class="footer_mobile">© 2020 Bizao All Rights Reserved</span>
                <a class="grey-text text-lighten-4 right items hide-on-small-only" href="https://bizao.com" target="_blank"><img src="../../../assets/images/globe.svg" alt="" class="align-img"> www.bizao.com</a>
                <a class="grey-text text-lighten-4 right items hide-on-small-only" href="#!"><img src="../../../assets/images/support.svg" alt="" class="align-img"> Support</a>
            </div>
        </div>
    </div>
</footer>