
 <div class="wrapper">
  <p class="section-title">Bizao API Testor</p>
  <p class="section-header">In this section, you can test all our APIs and notice yourself the easiness of use. </p>
  <div class="row">
     <div class="col s12">
       <ul class="tabs row ul_api_testor">
         <li class="tab col s4  m4 l3 tabs_first_element"><a [ngClass]="{'active' : tab === 'start'}"  href="#start" (click)="toggleToStart()">Getting started</a></li>
         <li class="tab col  s2 m4 l2"><a  [ngClass]="{'active' : tab === 'dcb'}" href="#dcb" (click)="toggleToDCB()" >DCB</a></li>
         <li class="tab col  s4 m4 l3"><a [ngClass]="{'active' : tab === 'mp'}" href="#mmapi" (click)="toggleToMMAPI()">Merchant Payment</a></li>
         <li class="tab col  s4 m4 l2"><a [ngClass]="{'active' : tab === 'mm'}" href="#mtapi" (click)="toggleToMTAPI()">MoneyTransfer</a></li>
         <li class="tab col  s4 m4 l2"><a [ngClass]="{'active' : tab === 'bp'}" href="#mm" (click)="toggletoBPQ()">Bulk Payment</a></li>
       </ul>
     </div>
     <div *ngIf="ismpclicked==true" class="col s12">
      <ul  class="tabs row noborder ul_api_testor">
        <li class="tab col s4  m4 l3 tabs_first_element"><a [ngClass]="{'active' : tab === 'mmapi'}" (click)="toggleToMMAPI()"  href="#mmapi" >Mobile Money</a></li>
        <li class="tab col  s2 m4 l4"><a  [ngClass]="{'active' : tab === 'dcb'}" href="#vmapi"  (click)="toggleToVMAPI()" >VISA MASTERCARD</a></li>


      </ul>
     </div>
<!--
     <div *ngIf="isbpclicked==true" class="col s12">
      <ul  class="tabs row noborder ul_api_testor">
        <li class="tab col s4  m4 l3 tabs_first_element"><a [ngClass]="{'active' : tab === 'bpapi'}" (click)=" toggletoBPQ()" href="#bpapi" >Bulk API</a></li>
        <li class="tab col  s2 m4 l4"><a  [ngClass]="{'active' : tab === 'bpgs'}" href="#bpgs"  >GetStatus API</a></li>


      </ul>

     </div>
       -->



     <div id="start" class="col s12 testor-content" *ngIf="tab == 'start' "><app-getting-started></app-getting-started></div>
     <div id="dcb" class="col s12 testor-content" *ngIf=" tab == 'dcb' "> <app-dcb></app-dcb> </div>
     <div id="mm" class="col s12 testor-content" *ngIf=" tab == 'mp' "><app-mobile-money></app-mobile-money></div>
     <div id="bpapi" class="col s12 testor-content" *ngIf=" tab == 'bpapi' "><app-bulkquery-api></app-bulkquery-api></div>
     <div id="bpgs" class="col s12 testor-content" *ngIf=" tab == 'bpgs' "><app-get-balance-api></app-get-balance-api></div>

     <div id="mmapi" class="col s12 testor-content" *ngIf=" tab == 'mmapi' "> <app-mobile-money-api></app-mobile-money-api></div>


     <div id="vmapi" class="col s12 testor-content" *ngIf=" tab == 'vmapi'"><app-visa-mastercard-api></app-visa-mastercard-api></div>
     <div id="mtapi" class="col s12 testor-content" *ngIf=" tab == 'mtapi'"><app-money-transfer-api></app-money-transfer-api></div>




   </div>
 </div>
