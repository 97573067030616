<div class="row">
  <div class="col s12 m10 l10">

      <!-- Start of first wrapper -->

      <div class="first-wrapper section scrollspy" id="principles">
          <p class="first-wrapper-title">3G/4G User Authentication (Redirect URL)</p>
          <br>
          <!-- Start of Principles section -->
          <p class="first-wrapper-title">1. Principles</p>
          <p>
              Bizao allows you also to authenticate your end-user with a URL redirect flow in
              implicit mode.
              The Bizao platform will notify you on your CallBack URL with the specific <span
                  class="light-span">BIZAO_TOKEN</span>
              and <span class="light-span">BIZAO_ALIAS</span>. <br>
              For each country’s 3G/4G-network you target, you have to use a dedicated redirection URL in
              the required format.
          </p>
          <!-- End of Principles section -->

            <!-- Start of List of 3G-redirection URL by country -->
            <p class="first-wrapper-title">2. List of 3G-redirection URL by country</p>
            <p>Below a list of authorized 3G-redirection URLs for Bizao by country :</p>
            <br>
            <table>
                <tr>
                    <td><b>Country-Name</b></td>
                    <td><b>Redirection URL</b></td>
                </tr>
                <tr>
                    <td>Ivory Coast </td>
                    <td><span class="keys"> http://waaat.orange.ci/BIZAO/?CallBack=SP-CallBackURL-in-
                            encodedformat</span></td>
                </tr>
                <tr>
                    <td>Cameroon</td>
                    <td class="keys">http://waaat.orange.cm/BIZAO/?CallBack=SP-CallBackURL-in-encodedformat
                    </td>
                </tr>
                <tr>
                    <td>Senegal</td>
                    <td class="keys"> http://waaat.orange.sn/BIZAO/?CallBack=SP-CallBackURL-in-
                        encodedformat</td>
                </tr>
                <tr>
                    <td>Congo-DR</td>
                    <td class="keys"> http://waaat.orange.cd/BIZAO/?CallBack=SP-CallBackURL-in-
                        encodedformat</td>
                </tr>
                <tr>
                    <td>Burkina Fasso</td>
                    <td class="keys">http://waaat.orange.bf/BIZAO/?CallBack=SP-CallBack -CallBackURL-
                        in-encoded-format</td>
                </tr>
            </table>
            <br><br>
            <p>Format of CallBack parameter :</p>
             <p>
                <b>a.</b> The CallBack URL is used as notification URL, Bizao will use it to send you the user
                credential. <br><br>
                <b>b.</b> The CallBack parameter must contain your URL in encoded format (you can use this
                site to encode your URL: <br> <a href="https://www.urlencoder.org/)" target="_blank"><span
                        class="keys">Click Here to encode in base64</span></a>.<br><br>
                <b>c.</b> For security reasons this URL should be in https (port 403 to be activated). <br><br>
                <b>d.</b> This URL will be used by Bizao to push you User’s credential (<span
                    class="light-span">BIZAO_TOKEN</span> &
                <span class="light-span">BIZAO_ALIAS</span> ) as Headers using a ‘GET query’. <br><br>
                <b>e.</b> You may receive the credentials in an encoded format. In that case you have to
                decode it before use. <br><br>
                <b>f.</b> You can add some correlation parameter within your CallBack URL, Bizao will
                propagate these parameters to your CallBack. <br><br>
                <b>g.</b> Your response to Bizao-notification must be http 302 with your Location-URL (send
                the full path of your HomePage URL. Do not make a second 3G redirection within
                this HomePage). <br><br>
                <b>h.</b> Bizao will forward this Location-URL to the end-user to let him continue the purchase
                journey.<br><br><br>
                Below a sample of complete 3G-redirection-URL with example of correlation data : <br><br>
            </p>
            <pre><p> <code
                class="code"> http://waaat.orange.bf/BIZAO/?CallBack=https%3A%2F%2FMyDomain%2FMyHomePage%3FCorrelationID%3Dxxxxxx</code></p> </pre>
            <p><b>Note</b>: To test the 3G/4G authentication flow please ensure that:</p>
            <p>
                <b>1.</b> You use the targeted country’s 3G/4G SIM Card. <br> <br>
                <b>2. </b> Make sure you’re getting proper mobile network. <br> <br>
                <b>3.</b> Make sure Wi-Fi is Turned off <br> <br>
                <b>4. </b> Enable Mobile Data OR 3G/4G from device settings. <br> <br>
                <b>5. </b> Go to Setting >> Data Saver >> Turn it OFF. <br> <br>
                <b>6. </b> Go to Setting >> Privacy >> Clear browsing cache. <br> <br>
            </p>
            <!-- End of List of 3G-redirection URL by country -->
        </div>
        <!-- End  of first wrapper -->

      <!-- Start of second wrapper -->

      <div class="second-wrapper section scrollspy" id="sample-example">

          <p>
              &#x2022;&nbsp;&nbsp;<b>End-user redirection once getting on your home page</b>
          </p>

          <br><br>
          <p><i class="material-icons style-icon">warning</i> <b>Be careful :</b></p>
          <p>
              If the end-user is connected to Wi-Fi, you will receive a <code><b>503</b></code> or
              <code><b>401</b></code> response from
              waaat.orange. Your backend has to manage this situation and switch the user to
              another authentication method like OTP. <br>
              After the redirection to waaat.orange is made and the user authenticated,
              a <span class="light-span">Bizao_token</span> and a <span class="light-span">Bizao_alias</span> will
              then be forwarded to your callback url.</p>
          <br>
          <p>&#x2022;&nbsp;&nbsp;<b>Response of your callback url once credentials received and managed</b></p>
          <div class="code"><pre><code>Header(“Location: aUrlOfYourChoiceToRedicrectTheEnd-userTo”);</code></pre></div>
          <br>
          <p>Some HTTP headers from the request made on your callback url :</p>
          <div class="code">
              <span class="keys">Resource</span>: <code>/3g/sp/notify?CallBack=yourCallBackUrl</code> <br>
              <span class="keys">Context</span>: <code>/auth/v1</code> <br>
              <span class="keys">X-Forwarded-Server</span>: <code>api.bizao.com</code> <br>
              <span class="keys">X-Forwarded-Host</span>: <code>api.bizao.com</code> <br>
              <span class="keys">Bizao-Alias</span>: <code>BIZAO_ALIAS encoded</code> <br>
              <span class="keys">Bizao-Token</span>: <code>BIZAO_TOKEN encoded</code>
          </div>
      </div>
      <!-- End of second wrapper -->

      <!-- Start of third wrapper -->
      <div class="third-wrapper section scrollspy" id="he-auth-error">
          <p class="first-wrapper-title">3. Authentication errors</p>
          <p>If the client is connected in Wi-Fi, Push mode authentication will not be successful. Bizao
              infrastructure will not send any Token and Alias.</p>
          <br>
          <table>
              <tr>
                  <td><b>HTTP status code</b></td>
                  <td><b>Code</b></td>
                  <td><b>Message</b></td>
                  <td><b>Description</b></td>
              </tr>
              <tr>
                  <td>-</td>
                  <td>-</td>
                  <td>We are unable to sign
                      you in. <br> Please try again</td>
                  <td>Error in waaat.orange url </td>
              </tr>
              <tr>
                  <td>401</td>
                  <td>900902 </td>
                  <td>Missing Credentials (XML)</td>
                  <td>When the 3G/4G
                      authentication is
                      attempted without a local
                      3G/4G network</td>
              </tr>
          </table>
      </div>
      <!-- End of third wrapper -->
  </div>

  <div class="col hide-on-small-only s12 m2 l2">
      <ul class="section table-of-contents">
          <li><p class="toc-title">On this page</p></li>
          <li><a href="#principles">Principles</a></li>
          <li><a href="#sample-example">Sample & errors</a></li>
      </ul>
  </div>
</div>
