import { Component, OnInit } from '@angular/core';
import { FetchDataService } from 'src/app/services/fetch-data.service';
declare var jQuery: any;
@Component({
  selector: 'app-visa-mastercard-api',
  templateUrl: './visa-mastercard-api.component.html',
  styleUrls: ['./visa-mastercard-api.component.css']
})
export class VisaMastercardApiComponent implements OnInit {

  constructor(private fetchDataService : FetchDataService) { }


  //find 5 day before date .
// Source: https://stackoverflow.com/a/1296374 .

key=""

  changeTodebitCard()
  {
    this.key="debitCard"
  }

  changeTogetStatVMapi()
  {
    this.key="getStatVMapi"
  }



  ngOnInit(): void {

    this.key="debitCard"

    var d = new Date();

    console.log(d.toLocaleDateString())
 d.setDate(d.getDate()-5);

 console.log(d.toLocaleDateString())


  }

}

