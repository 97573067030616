<p style="margin-left: 5%;" class="first-wrapper-title"> Get Balance Info</p>

<p> This service is used by IRT partners to display their balance details in our hub.
  It is necessary to pass the account name to display the balance.
</p>
<p> If the account name is passed, this specific account balance is displayed. If no account
  name is passed, all the accounts configured for this IRT is displayed.
</p>

<pre>
  <div class="code" >
Get /moneyTransfer/v1/<b>/getBalanceInfo/accountName</b> HTTP/1.1
Host:api.bizao.com
Authorization: Bearer cb422427-1wo6-3be2-b15b-sff651s7bs4e
type: bulk

                </div>
                </pre>

<pre>
  <div class="code" >
    <u><b>Samples Responses:</b></u>

    Content-Type: application/json
    &#123;

    &#123;
    "meta": &#123;
    "type": "account-view",
    "source": "bizao"

    &#125;

    &#123;

    "currency": "XOF",
    "reference": "sunu_test",
    "main_account_number": "sunu_test2001",
    "main_account_balance": 184.00,
    "advance_amount_provided": 1000.00,
    "loan_amount_taken": 0.00,
    "available_balance": 1184.00


    &#125;
    &#125;

    </div>
    </pre>

<br> <br>


<!--

  <p class="first-wrapper-title">Bizao transfer API common error code </p>





  <table>
    <tr>
        <th><b>ErrorCode</b> </th>
        <th><b>Exception Message Text</b></th>
        <th><b>Description/ Comments. </b></th>
        <th><b>HTTP Response Code </b></th>

    </tr>
    <tr>
        <td>50</td>
        <td>Forbidden access to the API
          </td>
        <td>Access denied by ACL.
          'Unauthorized Access Layer'
          or 'Unauthorized
          applicationId' or
          ‘Unauthorized country’
        </td>
        <td>
          403 Forbidden
        </td>

    </tr>
    <tr>
      <td>1201</td>
      <td>Forbidden access to the API</td>
      <td>Forbidden transaction</td>
      <td>403 Forbidden</td>
    </tr>
    <tr>
      <td>1202</td>
      <td>Forbidden access to the API</td>
      <td>Invalid merchant key</td>
      <td>403 Forbidden</td>
    </tr>
    <tr>
      <td>1203</td>
      <td>Forbidden access to the API</td>
      <td>Unauthorized currency for   this country</td>
      <td>403 Forbidden</td>
    </tr>

    <tr>
      <td>1204</td>
      <td>Forbidden access to the API</td>
      <td>Order Already exists. The
        order_id must be unique in
        the system. Only one Token
        per order_id</td>
      <td>403 Forbidden</td>
    </tr>


    </table>
    -->
    <!-- End of fourth wrapper -->

