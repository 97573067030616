import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-left-side-navigation',
  templateUrl: './left-side-navigation.component.html',
  styleUrls: ['./left-side-navigation.component.css']
})
export class LeftSideNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    // semantic-ui dropdown controller
    (function ($) {
      $('.dropdown-trigger').dropdown({
        alignement:'left',
        coverTrigger:true
      });
    })(jQuery);

    // materializecss modal controller
    (function ($) {
      $(document).ready(function(){
        $('.modal').modal();
      });
    })(jQuery);

}
}
