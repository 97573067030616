<div class="row">
    <div class="col s12 m6 l6">
        <p class="section-subtitle ">Steps</p>
        <hr style="border-color: #E3E8EE; border-style: solid;">
        <ul class="bar" >
            <li><a routerLink="#"  [ngClass]="{'is-active' : key === 'mm-pay-api'}" (click)="changeKeyToPay()">Payment API</a></li>
            <li><a routerLink="#"  [ngClass]="{'is-active' : key === 'check-mm-pay-status'}"(click)="changeKeyToGetStatus()">GetStatus API</a></li>
          </ul>
       
    </div>
    <div class="col s12 m6 l6">
        <p class="section-subtitle">Description</p>
        <hr style="border-color: #E3E8EE; border-style: solid;">
        <p *ngIf="key === 'mm-pay-api' "> The Payment API is used to charge the end user.</p>
        <p *ngIf="key === 'check-mm-pay-status' ">The GetStatus API is used to check the transaction status after payment</p>
    </div>
    </div>
    <hr style="border-color: #E3E8EE; border-style: solid;">

    <div *ngIf=" key === 'mm-pay-api'">
        <app-mobile-money-api></app-mobile-money-api>
    </div>
    <div *ngIf=" key === 'check-mm-pay-status'">
       <app-mobile-money-getstatus></app-mobile-money-getstatus>
    </div>