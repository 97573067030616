import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FetchDataService {

  constructor(private http: HttpClient) { }

  tab ='start';
  dcb_key = 'genOTP';
  mm_key = 'mm-pay-api';
  headers = {
    'X-AUTH-TOKEN': environment.ACCESS_TOKEN,
    'Content-Type': 'application/json'
  };

  get_access_token_headers = { 'Authorization': 'Basic OTAzODI5MTFhNTk0ZDFlM2U2MzljY2NiY2RlOGRlOWJzZWNydXJlLjM3MzUxMjgyYzk2ZjAxOWNhZmZhNGUwODA2M2FhZGZj' };
  fetch_access_token(): Observable<any> {
    return this.http.get(environment.GET_ACCESS_TOKEN_API, { headers: this.get_access_token_headers });
  }


  

  get_access_token_body = { "endpoint": "/token" }
  generate_otp_body = { "endpoint": "/challenge/v1/challenges" };
  validate_otp_body = { "endpoint": "/challenge/v1/challenges/{param}" };
  process_payment_dcb_body = { "endpoint": "/payment/v1/acr%3AOrangeAPIToken/transactions/amount" };
  mobile_money_body = { "endpoint": "/mobilemoney/v1" };
  mobile_money_getstatus_body = { "endpoint": "/mobilemoney/getstatus/v1/{param}" };
  dcb_get_notif_body = { "endpoint": "/smsmessaging/v1/outbound/{param}/requests" }


  create_access_token(token): Observable<any> {
    return this.http.post(environment.DATA_PROVIDER_API, this.get_access_token_body, { headers: {
      'X-AUTH-TOKEN': token,
      'Content-Type': 'application/json'
    } })
  }

  generate_challenge_otp(token): Observable<any> {
    return this.http.post(environment.DATA_PROVIDER_API, this.generate_otp_body, {
      headers: {
        'X-AUTH-TOKEN': token,
        'Content-Type': 'application/json'
      }
    }
    )
  }
  validate_challenge_otp(token): Observable<any> {
    return this.http.post(environment.DATA_PROVIDER_API, this.validate_otp_body, {
      headers: {
        'X-AUTH-TOKEN': token,
        'Content-Type': 'application/json'
      }
    })
  }
  process_payment_dcb(token): Observable<any> {
    return this.http.post(environment.DATA_PROVIDER_API, this.process_payment_dcb_body, {
      headers: {
        'X-AUTH-TOKEN': token,
        'Content-Type': 'application/json'
      }
    })
  }
  mobile_money_payment(token): Observable<any> {
    return this.http.post(environment.DATA_PROVIDER_API, this.mobile_money_body, {
      headers: {
        'X-AUTH-TOKEN': token,
        'Content-Type': 'application/json'
      }
    })
  }
  mobile_money_getstatus(token): Observable<any> {
    return this.http.post(environment.DATA_PROVIDER_API, this.mobile_money_getstatus_body, {
      headers: {
        'X-AUTH-TOKEN': token,
        'Content-Type': 'application/json'
      }
    })
  }

  dcb_get_notif(token): Observable<any> {
    return this.http.post(environment.DATA_PROVIDER_API, this.dcb_get_notif_body, {
      headers: {
        'X-AUTH-TOKEN': token,
        'Content-Type': 'application/json'
      }
    })
  }

}