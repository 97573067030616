import { Component, OnInit, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FetchDataService } from '../../../services/fetch-data.service';
declare var jQuery: any;
@Component({
  selector: 'app-mobile-money-getstatus',
  templateUrl: './mobile-money-getstatus.component.html',
  styleUrls: ['./mobile-money-getstatus.component.css']
})
export class MobileMoneyGetstatusComponent implements OnInit,AfterViewInit{
  req_headers:any=null;
  req_body: any = null;
  res_headers:any=null;
  res_body: any = null;
  pulse: boolean = true;
  constructor(private fetchDataService: FetchDataService) { }
  sendRequest() {
    this.fetchDataService.mobile_money_getstatus(environment.ACCESS_TOKEN).subscribe(
      response => {
        if(response[0].doc.response.success.header.length == 0) {
          this.res_headers= "There are no headers in the response";
          this.res_body = response[0].doc.response.success.body;
        }
        else if(response[0].doc.response.success.body.length == 0) {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = "There is no body in the response";
        }
        else {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = response[0].doc.response.success.body;
        }
      }
    );
  }

  togglePulse() {
    this.pulse = false;
  }

  ngOnInit(): void {
    (function ($) {
      $(document).ready(function () {
        $('.tabs').tabs();
      });

      $(document).ready(function () {
        $('.scrollspy').scrollSpy();
      });
    })(jQuery);
  
  }

  ngAfterViewInit() {
    this.fetchDataService.mobile_money_getstatus(environment.ACCESS_TOKEN).subscribe(
      response => {
        this.req_headers=response[0].doc.request.header;
        this.req_body="There is no body in the request";
      }
    ); 
  }

}
