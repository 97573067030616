import { Component, OnInit } from '@angular/core';
import {FetchDataService} from '../../services/fetch-data.service';
declare var jQuery: any;
@Component({
  selector: 'app-orange-dcb-notif-api',
  templateUrl: './orange-dcb-notif-api.component.html',
  styleUrls: ['./orange-dcb-notif-api.component.css']
})
export class OrangeDcbNotifApiComponent implements OnInit {

  constructor(private fetchDataService : FetchDataService) { }
  switchToNotifOTP(){
    this.fetchDataService.tab='dcb';
    this.fetchDataService.dcb_key = 'notif';
  }

  ngOnInit(): void {
    (function ($) {
      
      $(document).ready(function(){
       $('.scrollspy').scrollSpy();
       $('.row .table-of-contents').pushpin({ top: 80 });
     }); 
   })(jQuery);
   
  }

}
