import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FetchDataService } from 'src/app/services/fetch-data.service';
declare var jQuery: any;

@Component({
  selector: 'app-debit-card-api',
  templateUrl: './debit-card-api.component.html',
  styleUrls: ['./debit-card-api.component.css']
})
export class DebitCardAPIComponent implements OnInit, AfterViewInit {
  req_headers:any=null;
  req_body: any = null;
  res_headers:any=null;
  res_body: any = null;
  pulse: boolean = true;
  res_header_active:boolean=false;



  constructor(private fetchDataService: FetchDataService) {

    this.req_headers={
      "authorization": "Bearer 483a05b2-25e8-314f-8db3-dc8081547458",
      "Content-Type": "application/json",
      "country-code": "sn",
      "mno-name" : "orange",
      "lang": "en",
      "channel":"web",
      "category":"education"



    }

    this.req_body=
    {
      "currency": "XOF",
      "order_id": "MyMerchantNAme_ID",
      "amount": 10000000,
      "return_url": "http://www.success.com",
      "cancel_url": "http://www.cancel.com",
      "reference": "REFMerchantName",
      "state": "test CM"
    }
  }

  sendRequest() {

this.res_headers="There are no headers in the response"

this.res_body=
  {
    "status": "201",
         "message": "OK",
         "order_id": "Transction Id of the merchant",
         "state": "test CM",
         "payment_url": "https://preproduction-visamc.bizao.com/visa-mc/7e07e4d4-a3fd47a4-8d1e-360c6647bbab"
  }

    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {

        console.log(response[0])
        if(response[0].doc.response.success.header.length == 0) {
          this.res_headers= "There are no headers in the response";
          this.res_body = response[0].doc.response.success.body;
        }
        else if(response[0].doc.response.success.body.length == 0) {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = "There is no body in the response";
        }
        else {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = response[0].doc.response.success.body;
        }
      }
    );
    */
    this.res_header_active = true
  }

  togglePulse() {
    this.pulse = false;
  }

  ngOnInit(): void {
    (function ($) {
      $(document).ready(function () {
        $('.tabs').tabs();
      });

      $(document).ready(function () {
        $('.scrollspy').scrollSpy();
      });
    })(jQuery);



  }

  ngAfterViewInit() {
    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {
        console.log(response[0])
        this.req_headers=response[0].doc.request.header;
        this.req_body=response[0].doc.request.body;
      }
    );
    */
  }


}
