import { Component, OnInit } from '@angular/core';
import {FetchDataService} from '../../../services/fetch-data.service';
@Component({
  selector: 'app-mobile-money',
  templateUrl: './mobile-money.component.html',
  styleUrls: ['./mobile-money.component.css']
})
export class MobileMoneyComponent implements OnInit {

  constructor(private fetchDataService : FetchDataService) { }
  key = this.fetchDataService.mm_key;

  changeKeyToPay(){
    this.key = "mm-pay-api";
  }
  changeKeyToGetStatus(){
    this.key = "check-mm-pay-status";
  }
  
   
  ngOnInit(): void {
  }

}
