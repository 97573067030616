<div class="row">
    <div class="first-wrapper">
        <p class="first-wrapper-title">Mobile Originated User Authentication </p>
        <br>
        <p class="first-wrapper-title">1. Principles</p>
        <p>
            Bizao allows you another type of implicit authenticate mechanism: <b>SMS-MO (Mobile Originated)</b>. <br><br>
            This type of authentication requires a dedicated <b><i>Short-Code</i></b> by <u><i>Service-Provider</i></u> and by
            <u><i>Country</i></u>. <br><br>
            To start this Authentication, the Service-Provider application should send a binary SMS from
            the user device. <br><br>
            The Bizao platform will use the Service-Provider’s Short-Code to route this SMS to the Service
            Provider’s back-end. <br><br>
            Bizao will generate and push a user credential (<span class="light-span">BIZAO-TOKEN</span> & <span class="light-span">BIZAO-ALIAS</span> ) to a
            ServiceProvider’s CallBack-URL as Header of GET-Query.
        </p>
    </div>
</div>