<div class="row">
    <div class="col s12 m10 l10">
        <!-- Start first wrapper  -->
        <div class="first-wrapper section scrollspy" id="principles">
            <p class="first-wrapper-title">Orange DCB Payment API</p>
            <br>
            <p class="first-wrapper-title">1. Principles</p>
            <p>To Charge the End User, you first need to generate the user bizao-token and alias using one
              of three Authentication mechanims described previously.</p>

              <p>
                <b> Note : Payment/v2 is available in OCI/OCM/OBF/OSN/ODC/OML/OMA/OTN </b>
              </p>
            <br>
            <p class="first-wrapper-title">2. Description</p>
            <p>This payment method is a one-time API, we manage a new version of this API (v2) that are
                using bizao-alias in stread of bizao-token as for the v1 To charge your end user you will use:</p>
  
                &#x2022; <span class="keys">BIZAO-URI</span> : <em>/payment/v2/acr:X-Orange-ISE2/transactions/amount</em> <br><br>
             
            &#x2022; <span class="keys">BIZAO-HOST</span> : <code>api.bizao.com</code>



            <br><br><br>
            <p>You query will contain the Mandatory Headers below: </p>

            <table>
                <tr>
                    <td> <b>Header</b> </td>
                    <td><b>Description/Content</b> </td>
                </tr>
                <tr>
                    <td>Bizao-alias </td>
                    <td> the <span class="light-span">BIZAO-ALIAS</span> that you should generate using one of
                        Authentication method above</td>
                </tr>
                <tr>
                  <td>Bizao-token</td>
                  <td>the <span class="light-span">BIZAO-TOKEN </span> that you should generate using one of
                      Authentication method above <br> (OrangeApiToken in this
                      current version of authentication).</td>
              </tr>
                <tr>
                    <td>Authorization</td>
                    <td>YOUR_ACCESS_TOKEN</td>
                </tr>
                <tr>
                  <td>mno-name</td>
                  <td>(string) the name of operator you targetexample : orange. Bizao
                    will update you regarding the new mno set in the hub
                    </td>
              </tr>
              <tr>
                <td>country-code</td>
                <td>(string)2 characters code of the country you target ( use this norme
                  : ISO 3166 alpha 2 country code, url to get the all country-code list:
                  https://www.iban.com/country-codes
                  (for instance : &#60;tn&#62; is the country-code for Tunisia.</td>
            </tr>
            <tr>
              <td>serviceID</td>
              <td>(string) Always <b>“BIZAO”</b></td>
          </tr>
          <tr>
            <td>Sp-id</td>
            <td>(string), the SP dedicated Service ID that Bizao will communicate
              you (Sample: &#60;abcd&#62;)</td>
        </tr>
        <tr>
          <td>Product-id</td>
          <td>(string) The SP dedicated product id that Bizao will communicate
            you (Sample: &#60;abcd daily&#62;</td>
      </tr>

            </table>
            <br><br>
            <p class="hide_on_mobile">The body of your query will contain the parameters below :</p>
            <br>
            <table class="hide_on_mobile">
                <tr>
                    <td><b>Parameter name</b> </td>
                    <td><b>Description</b></td>
                    <td><b>Usage</b></td>
                </tr>
                <tr>
                    <td>amount</td>
                    <td>&#40;decimal&#41; amount to be charged.Note : Please note that the currency code “USD”
                      amount for DR Congo refers actually to Unit (U). It has
                      the following ratio: 1 USD / 100 UNITS.
                      </td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>currency</td>
                    <td>&#40;string&#41; currency identifier as defined in
                        &#x5b;ISO4217&#x5d;. <br> Note &#40;as described for the amount
                        parameter&#41; either currency and <br> amount or
                        code must be specified. <br>
                        you can use this site to know the currency-code
                        by country: <br>
                        <a href="https://fr.iban.com/currencycodes.html">See currency-codes of countries</a></td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>clientCorrelator</td>
                    <td>&#40;string&#41; uniquely identifies this create charge
                        request. If there is a communication failure
                        during <br> the charge request. Using the same
                        clientCorrelator when retrying the request allows
                        the operator <br> to avoid applying the same charge
                        twice. This field SHOULD be present.</td>
                    <td>Optional</td>
                </tr>
                <tr>
                    <td>description</td>
                    <td>&#40;string&#41; description text to be used for
                        information and billing text</td>
                    <td>Mandatory </td>
                </tr>
                <tr>
                    <td>endUserId</td>
                    <td>&#40;string&#41; Always <b>acr:X-Orange-ISE2</b>. </td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>onBehalfOf</td>
                    <td>&#40;string&#41; allows aggregators/partners to specify
                        the merchant-service name.  <b>Note</b> :
                        Must contains the same value as  &#60;SPNameServiceName &#62;</td>
                    <td>Mandatory</td>
                </tr>

                <tr>
                    <td>transactionOperationStatus</td>
                    <td>&#40;string&#41; specifies the payment operation required.
                        In this case set to ‘Charged’ <br> according to the
                        DCB Standard.</td>
                    <td>Mandatory</td>
                </tr>
            </table>
            <br>

            <b>Charge query sample: </b>


            <div class="sample-wrapper">
                <span class="light-span">Request sample :</span><br>
                <pre>
            <div class="code">
                curl --location --request POST 'https://api.bizao.com/payment/v2/acr%3AX-OrangeISE2/transactions/amount' \
                --header 'authorization: Bearer 4d34d3bd-3acb-35c4-bd37-6050d9fd9650' \
                --header 'cache-control: no-cache' \
                --header 'Content-Type: application/json' \
                --header 'bizao-token:
                B64k0dOzE96eo1laO9xEqyAQ5dUmWXIgd289i7DTcn9G08rTDVdZWU4G5tfl3+F4e3Z|MCO=OCI|tcd=1563
                480095|ted=1563480195|pBvsc7Jb1XycCnj88rgbz1iqBqQ=' \
                --header 'bizao-alias: PDKSUB-200-0nNZkFpbougrL2KQQTQVKpvO7f/mmOO816X5txYKzOA=' \
                --header 'country-code: ci' \
                --header 'mno-name: orange' \
                --header 'sp-id: Bright Mobile' \
                --header 'product-id: Viddigo daily CI' \
                --header 'Cookie: SERVERID=s0' \
--data-raw '&#123;
"amountTransaction": &#123;
"endUserId": "acr:X-Orange-ISE2",
"paymentAmount": &#123;
"chargingInformation": &#123;
"amount": "0.1",
"currency": "XOF",
"description": "Viddigo daily wen"
&#125;,
"chargingMetaData": &#123;
"onBehalfOf": "Bright Mobile - Viddigo",
"serviceId": "BIZAO_HYB"
&#125;
&#125;,
"transactionOperationStatus": "Charged",
"clientCorrelator": "Ref-5561234",
"antifraudId":"123456789"
&#125;
&#125;'
            </div>
            </pre>
             <br>
            <p><b>Note </b>: <br> For each new payment-query you have to set a new value for
                <b><i>“clientCorrelator” </i></b> parameter otherwise the Bizao BackEnd will not handle a new
                payment on this user</p>
            <br>
            <span class="light-span">Success Response Sample :</span>
              <pre>
            <div class="code">
               &#123;
                "amountTransaction":  &#123;
                "endUserId": "acr:OrangeAPIToken",
                "paymentAmount":  &#123;
                "chargingInformation":  &#123;
                "description": "MYProduct ",
                "currency": "TND",
                "amount": 0.01
                 &#125;,
                "totalAmountCharged": 0.01,
                "chargingMetaData":  &#123;
                "onBehalfOf": "Viddigo daily ",
                "serviceId": "BIZAO"
                 &#125;
                 &#125;,
                "transactionOperationStatus": "Charged",
                "referenceCode": "abtx-3333HR-xpoaqs-xxxxxxxxxx",
                "serverReferenceCode": "388bcd04-8a97-416d-8390-d54afabad346",
                "clientCorrelator": "Ref-5561234",
                "resourceURL": "https://gateway1a.mife.slamobile.com.my:8243/payment/v1/acr%3AOrangeAPIToken/transactions/amount/15421354654538P
                A158065263"
                 &#125;
                 &#125;

            </div>
            </pre>

            </div>
            <br><br>
            <p> <b>Important</b>: If you receive a success response but with the same
                <b><i>“serverReferenceCode”</i></b> it
                means that you already attempted to charge with the same <b><i>"clientCorrelator"</i></b>. In that case
                your charging request won’t get through.</p>
        </div>
        <!-- End of first wrapper -->

        <!-- Start of second wrapper -->
        <div class="second-wrapper section scrollspy" id="errors">
            <p class="first-wrapper-title">3. Payment error code</p>
            <table>
                <tr>
                    <th><b>ErrorCode</b></th>
                    <th><b>Exception text</b></th>
                    <th><b>Variables</b></th>
                    <th><b>Error Description</b></th>
                </tr>
                <tr>
                    <td>POL0253</td>
                    <td>Payment operation refused by user</td>
                    <td>none</td>
                    <td>403 Forbidden</td>
                </tr>
                <tr>
                    <td>POL0254</td>
                    <td>The amount exceeds the
                        operator limit for a single Forbidden
                        charge</td>
                    <td>none</td>
                    <td>403 Forbidden</td>
                </tr>
                <tr>
                    <td>POL1000</td>
                    <td>User has insufficient credit for
                        transaction</td>
                    <td>none</td>
                    <td>403 Forbidden</td>
                </tr>

                <tr>
                    <td>POL1001</td>
                    <td>The %1 operator charging limit for this user has been exceeded</td>
                    <td>%1 – the time period for which the charging limit has been reached, e.g. hourly daily, weekly, monthly.</td>
                    <td>403 Forbidden</td>
                </tr>
                <tr>
                    <td>POL1002</td>
                    <td>The charge happened too soon after the previous one</td>
                    <td>none</td>
                    <td>403 Forbidden</td>
                </tr>

                <tr>
                    <td>POL1004</td>
                    <td>Refund below minimum refundable amount supported %1 </td>
                    <td>%1 – the minimum refundable amount supported</td>
                    <td>403 Forbidden</td>
                </tr>
                <tr>
                    <td>POL1005</td>
                    <td>A refund request requires the original Server Reference Code for the charge that is being refunded</td>
                    <td>none</td>
                    <td>403 Forbidden</td>
                </tr>
                <tr>
                    <td>POL1006</td>
                    <td>originalServerReference<br>Code
                        not valid </td>
                    <td>none</td>
                    <td>403 Forbidden</td>
                </tr>
                <tr>
                    <td>POL2010</td>
                    <td>Inconsistent transaction </td>
                    <td>none</td>
                    <td>400 Bad
                        Request</td>
                </tr>
                <tr>
                    <td>POL2011</td>
                    <td>Transaction expired or invalid </td>
                    <td>none</td>
                    <td>400 Bad
                        Request</td>
                </tr>
                <tr>
                    <td>SVC0007</td>
                    <td>Invalid charging information  </td>
                    <td>none</td>
                    <td>400 Bad
                        Request</td>
                </tr>
                <tr>
                    <td>SVC0270</td>
                    <td>Charging operation failed, the charge was not applied </td>
                    <td>none</td>
                    <td>400 Bad Request</td>
                </tr>

            </table>

</div>.
