<div class="row">
  <div class="col s12 m10 l10">

    <!-- Start of first wrapper -->


    <div class="first-wrapper section scrollspy" id="generate-challenge">

      <br>
      <!-- Start of Principles Section -->
      <p class="first-wrapper-title">GETSTATUS API</p>

      <p class="first-wrapper-title">1. Principles </p>

      <p>
        The bulkpayment customer can check any transactions in a batch via the “getStatus” service.
        <br>
        “getStatus” API
        </p>


        <p class="first-wrapper-title">2. Description </p>

        <p> Bizao getStatus API Resful/json API. Below syntax and description  </p>

        <p style="margin-left: 5%;"><b>Api-name:</b> “bulk/v1/getStatus”</p>
        <p style="margin-left: 5%;"><b>batchNumber:</b> the batchNumber of the bulk transaction.</p>
        <p style="margin-left: 5%;"><b>Based URL:</b> </p>

        <p style="margin-left: 10%;"><b> Preproduction:</b> <span class="light-span"> https://preproduction-gateway.bizao.com </span></p>
        <p style="margin-left: 10%;"> <b> Production:</b>  <span class="light-span"> https://api.bizao.com/</span></p>

        <p style="margin-left: 5%;"> Method: GET</p>


        <p style="margin-left:10% ;"><b>2.1 “getStatus” API query syntax :</b></p>

        <p> To call the “getStatus” service, below is the syntax:
          https://api.bizao.com/bulk/v1/getStatus/ &#123;batchNumber &#125;</p>

          “getstatus” sample query :

          <pre>
            <div class="code" >
curl --location --request GET 'https://api.bizao.com/bulk/v1/getStatus/bulk-sn_07-10- 2021_01' \
--header 'Authorization: token’ \
--header 'Content-Type: application/json' \
--header 'channel: web' \
--header 'type: bulk' \
--header 'Cookie: AlteonMS17=AVGuRvbEYwp+/a0U3c6Ndg$$; SERVERID=s0;
SRV=c4929e28-54ed-4c57-bec6-a50f7151ac41'
              </div>
          </pre>

          <p style="margin-left:10% ;"><b>2.2 Getstatus API response</b></p>

<p>
    Bizao getstatus API will response in Json format.
    This Json format/response has the same format as for notification flow.
</p>

<pre>
  <div class="code">
    &#123;
      "meta": &#123;
      "source": "bizao",
      "merchant-name": "bizao-bsc@carbon.super",
      "type": "bulk",
      "currency": "XOF",
      "batchNumber": "bulk-sn_07-10-2021_01",
      "reference": "bsc-bulk-mt-XOF",
      "feesType": "FIXED_FEE",
      "lang": "fr",
      "totalAmount": 11.00,
      "totalFees": 2.00,
      "senderFirstName": "Damith",
      "senderLastName": "Sulochana",
      "senderAddress": "Colombo",
      "senderMobileNumber": "2250512345678",
      "fromCountry": "cm"
      &#125;, "data": [
    &#123;
      "id": "001",
      "orderId": "bulk-sn_07-10-2021_01-001",
      "mno": "free",
      "beneficiaryFirstName": "Salif",
      "beneficiaryLastName": "KA",
      "beneficiaryAddress": "",
      "beneficiaryMobileNumber": "221765151504",
      "toCountry": "sn",
      "feesApplicable": "Yes",
      "amount": 10.00,
      "fees": 1.00,
      "status": "Successful",
      "intTransaction-Id": "1aa27e52-969b-4fd1-8012-da5606b8de71",
      "extTransaction-Id": "CI211007.1411.A95748"
      &#125;,
    &#123;
      "id": "002",
      "orderId": "bulk-sn_07-10-2021_01-002",
      "mno": "orange",
      "beneficiaryFirstName": "Logan",
      "beneficiaryLastName": "Doo",
      "beneficiaryAddress": "Rue 10 angle 20, Medina, Dakar",
      "beneficiaryMobileNumber": "221775782348",
      "toCountry": "sn",
      "feesApplicable": "Yes",
      "amount": 1.00,
      "fees": 1.00,
      "status": "Failed",
      "failedReason": "Validation failed, please contact support",
      "intTransaction-Id": "4be22560-389b-4c69-9e45-e6aa7452b7dd",
      "extTransaction-Id": "" &#125;
]
&#125;
  </div>
</pre>


    <!-- End of fourth wrapper -->




</div>
