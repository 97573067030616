import { Component, OnInit} from '@angular/core';
import { FetchDataService } from '../../../services/fetch-data.service';
import {environment} from '../../../../environments/environment';
declare var jQuery: any;
@Component({
  selector: 'app-dcb-validate-otp',
  templateUrl: './dcb-validate-otp.component.html',
  styleUrls: ['./dcb-validate-otp.component.css']
})
export class DcbValidateOtpComponent implements OnInit {

  req_headers:any = null ;
  req_body: any = null;
  res_headers:any = null;
  res_body: any = null;
  pulse: boolean = true;
  constructor(private fetchDataService: FetchDataService) { }
  sendRequest() {
    this.fetchDataService.validate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {
        if(response[0].doc.response.success.header.length == 0) {
          this.res_headers= "There are no headers in the response";
          this.res_body = response[0].doc.response.success.body;
        }
        else if(response[0].doc.response.success.body.length == 0) {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = "There is no body in the response";
        }
        else {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = response[0].doc.response.success.body;
        }
      }
    );
  }

  togglePulse() {
    this.pulse = false;
  }


  ngOnInit(): void {
    console.log(environment.ACCESS_TOKEN);
    (function ($) {
      $(document).ready(function () {
        $('.tabs').tabs();
      });

      $(document).ready(function () {
        $('.scrollspy').scrollSpy();
      });
    })(jQuery);
  }

  ngAfterViewInit() {
   
    this.fetchDataService.validate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {
        this.req_headers=response[0].doc.request.header;
        this.req_body=response[0].doc.request.body;
      }
    ); 
  }

}
