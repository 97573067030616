<div class="row">
    <div class="col s12 m10 l10">
        <div class="first-wrapper section scrollspy" id="principles">
            <p class="first-wrapper-title">SMS MO STOP Subscription</p> <br>
            <p class="first-wrapper-title">1. Principles</p>
           <p> So far, this feature is mandatory in Ivory Coast and DR Congo.</p>
           <br>
           <p>It allows users to notify the SP of their wish to unsubscribe by sending a SMS to
            appropriate short-code.</p>

        </div>

        <p class="first-wrapper-title">2. Description</p>

        <p>Please find below the elements needed to set up from our side the feature:</p>

        <p style="margin-left: 10%;">1. <b>A notification URL</b>: the URL where Bizao will notify/send you the user-MO-SMS</p>
        <p style="margin-left: 10%;">2. <b>A keyword:</b> the code that your end-user will send followed by the word
          “STOP”
          </p>

          <p style="margin-left: 10%;"> <em>Sample: “C02 STOP” to 7752</em></p>

          <p>
            Please note that a keyword can be linked to only one URL but a URL can be linked
to many keywords
          </p>


          <p class="first-wrapper-title">3. Short-codes available</p>

          <p>Below the list of existing short-codes by county:</p>

          <table>
            <tr>
                <td><b>Country</b></td>
                <td><b>Short-Code</b></td>

            </tr>
            <tr>
                <td>Ivory-
                    Coast</td>
                <td>7752</td>

            </tr>
            <tr>
                <td>RDCongo</td>
                <td>444888</td>

            </tr>
            <tr>
                <td>Cameroon</td>
                <td>8924</td>

            </tr>
            <tr>
                <td>Senegal</td>
                <td>21004</td>

            </tr>
            <tr>
                <td>Tunisia</td>
                <td></td>

            </tr>
            <tr>
                <td>Burkina
                    faso</td>
                <td>383</td>

            </tr>
        </table>

<br>
<br>


          <p class="first-wrapper-title">USSD STOP Subscription</p>
          <br>
          <!-- Start of Principles section -->
          <p class="first-wrapper-title">1. Principles</p>
          <p>
            So far, this feature is available in Ivory Coast, Cameroun, Senegal
            </p>


            <p>
              It allows users to unsubscribe from a SP service by sending a USSD code.
              Example : #303*10# for unsubscribtion with OCI
          </p>


          <!-- End of Principles section -->

          <!-- Start of List of 3G-redirection URL by country -->
          <p class="first-wrapper-title">2. Description</p>


          <p>Please find below the flow of the feature:</p>


          <p style="margin-left: 5%;">

            <b>1.A endUser : </b>decide to unsubscribe from the service by sending a ussd code.
          </p>

          <p style="margin-left: 5%;">

            <b>2. Orange : </b>will deactivate the Subscription from his side and send a notification
            to Bizao’s notification_url.
          </p>

          <p>
            Sample notification: in the notification below, evenType is <b>« orderDeletion »</b>
          </p>

          <pre>
            <div class="code">
      &#123;
      "note":&#123;
      "text":"partner data"
      &#125;,
      "event":&#123;
      "id":"60585db6f59e4e66ac506a97",
      "relatedParty":[
      &#123;
      "id":"PDKSUB-200-UNcG4m15CednnxdFqXJNfX5CfDh\/BcEqqhNREfyKh+A=",
      "name":"ISE2",
      "role":"subscriber",
      "aliases":null,
      "individual":null
      &#125;,&#123;
      "id":"BIZAO",
      "name":"BIZAO",
      "role":"partner",
      "aliases":null,
      "individual":null
      &#125;,&#123;
      "id":"Funcell",
      "name":"Funcell",
      "role":"retailer",
      "aliases":null,
      "individual":null
      &#125;
      ],
     "order":&#123;
     "id":"60585db6f59e4e66ac506a94",
     "creationDate":"2021-03-22T09:04:54.189Z",
     "state":"Completed",
     "orderItem":&#123;
     "chargedAmount":80,
     "currency":"XAF",
     "validityDate":"2021-03-23T09:04:54.111Z",
     "nextCharge":"2021-03-23T09:04:54.111Z",
     "product":&#123;
     "id":"Funcell daily CM",
     "href":"na",
     "productCharacteristic":[
     &#123;
     "name":"periodicity",
     "value":"86400"
     &#125;,
     &#123;
     "name":"startDateTime",
     "value":"2021-03-22T09:04:54.111Z[UTC]"
     &#125;,
     &#123;
     "name":"endDateTime",
     "value":"2021-03-23T09:04:54.111Z[UTC]"
     &#125;
     ] &#125;
     &#125;
     &#125;
     &#125;,
     "eventTime":"2021-03-22T09:04:57.402Z",
     "eventType":"orderDeletion",
     "country":"xx",
     "provider":"digipay",
     "at_received":"2021-03-22 09:04:57"
    &#125;
            </div>
            </pre>

            <p style="margin-left: 5%;">

              <b>3.Bizao : </b>will send the unsubscription notification to SP’s notification_url.
            </p>

            <p style="margin-left: 5%;">

              <b>4. SP : </b>will unsubscribe the user from his base.
            </p>


            <p class="first-wrapper-title">3.Ussd-codes available</p>


          <p>Below a list of authorized 3G-redirection URLs for Bizao by country :</p>
          <br>

          <p>Below the list of existing ussd-codes by county: </p>

          <br>

          <table>
            <tr>
              <th>Country</th>
              <th>Ussd-code</th>
            </tr>

            <tr>
              <td>Ivory-Coast</td>
              <td>#303*10# </td>
            </tr>
            <tr>
              <td>RDCongo</td>
              <td>#303*10# </td>
            </tr>
            <tr>
              <td>Cameroon</td>
              <td>#203*10#  </td>
            </tr>
            <tr>
              <td>Senegal</td>
              <td>#303*10# </td>
            </tr>
            <tr>
              <td>Burkina Faso</td>
              <td>#303*10# </td>
            </tr>

          </table>

