<div class="row">
  <div class="col s12 m10 l10">

      <!-- Start of first wrapper -->

      <div class="first-wrapper section scrollspy" id="principles">
          <p class="first-wrapper-title">USSD STOP Subscription</p>
          <br>
          <!-- Start of Principles section -->
          <p class="first-wrapper-title">1. Principles</p>
          <p>
            So far, this feature is available in Ivory Coast, Cameroun, Senegal
            </p>


            <p>
              It allows users to unsubscribe from a SP service by sending a USSD code.
              Example : #303*10# for unsubscribtion with OCI
          </p>


          <!-- End of Principles section -->

          <!-- Start of List of 3G-redirection URL by country -->
          <p class="first-wrapper-title">2. Description</p>


          <p>Please find below the flow of the feature:</p>


          <p style="margin-left: 5%;">

            <b>1.A endUser : </b>decide to unsubscribe from the service by sending a ussd code.
          </p>

          <p style="margin-left: 5%;">

            <b>2. Orange : </b>will deactivate the Subscription from his side and send a notification
            to Bizao’s notification_url.
          </p>

          <p>
            Sample notification: in the notification below, evenType is <b>« orderDeletion »</b>
          </p>

          <pre>
            <div class="code">
      &#123;
      "note":&#123;
      "text":"partner data"
      &#125;,
      "event":&#123;
      "id":"60585db6f59e4e66ac506a97",
      "relatedParty":[
      &#123;
      "id":"PDKSUB-200-UNcG4m15CednnxdFqXJNfX5CfDh\/BcEqqhNREfyKh+A=",
      "name":"ISE2",
      "role":"subscriber",
      "aliases":null,
      "individual":null
      &#125;,&#123;
      "id":"BIZAO",
      "name":"BIZAO",
      "role":"partner",
      "aliases":null,
      "individual":null
      &#125;,&#123;
      "id":"Funcell",
      "name":"Funcell",
      "role":"retailer",
      "aliases":null,
      "individual":null
      &#125;
      ],
     "order":&#123;
     "id":"60585db6f59e4e66ac506a94",
     "creationDate":"2021-03-22T09:04:54.189Z",
     "state":"Completed",
     "orderItem":&#123;
     "chargedAmount":80,
     "currency":"XAF",
     "validityDate":"2021-03-23T09:04:54.111Z",
     "nextCharge":"2021-03-23T09:04:54.111Z",
     "product":&#123;
     "id":"Funcell daily CM",
     "href":"na",
     "productCharacteristic":[
     &#123;
     "name":"periodicity",
     "value":"86400"
     &#125;,
     &#123;
     "name":"startDateTime",
     "value":"2021-03-22T09:04:54.111Z[UTC]"
     &#125;,
     &#123;
     "name":"endDateTime",
     "value":"2021-03-23T09:04:54.111Z[UTC]"
     &#125;
     ] &#125;
     &#125;
     &#125;
     &#125;,
     "eventTime":"2021-03-22T09:04:57.402Z",
     "eventType":"orderDeletion",
     "country":"xx",
     "provider":"digipay",
     "at_received":"2021-03-22 09:04:57"
    &#125;
            </div>
            </pre>

            <p style="margin-left: 5%;">

              <b>3.Bizao : </b>will send the unsubscription notification to SP’s notification_url.
            </p>

            <p style="margin-left: 5%;">

              <b>4. SP : </b>will unsubscribe the user from his base.
            </p>


            <p class="first-wrapper-title">3.Ussd-codes available</p>


          <p>Below a list of authorized 3G-redirection URLs for Bizao by country :</p>
          <br>

          <p>Below the list of existing ussd-codes by county: </p>

          <br>

          <table>
            <tr>
              <th>Country</th>
              <th>Ussd-code</th>
            </tr>

            <tr>
              <td>Ivory-Coast</td>
              <td>#303*10# </td>
            </tr>
            <tr>
              <td>RDCongo</td>
              <td>#303*10# </td>
            </tr>
            <tr>
              <td>Cameroon</td>
              <td>#203*10#  </td>
            </tr>
            <tr>
              <td>Senegal</td>
              <td>#303*10# </td>
            </tr>
            <tr>
              <td>Burkina Faso</td>
              <td>#303*10# </td>
            </tr>

          </table>

</div>
