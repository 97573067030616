import { Component, OnInit } from '@angular/core';
declare var jQuery: any;
@Component({
  selector: 'app-sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.css']
})
export class SandboxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    (function ($) {
      $(document).ready(function(){
        $('.modal').modal();
      });
    })(jQuery);
  }

}
