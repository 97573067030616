<div class="row">
    <div class="col s12 m10 l10">
        <!-- Start of first wrapper -->
        <div class="first-wrapper section scrollspy" id="principles">
            <p class="first-wrapper-title">
                Mobile Money Payment API

            </p>
            <br>
            <p class="first-wrapper-title">1. Service Providers access to Bizao APIs</p>
            <p>Bizao gateway gives you access to a large panel of services through REST APIs.
                Mobile Money is one of these services.</p>
            <p>This service is based on a <b>&#x2039;mobilemoney&#x203A;</b> northbound API that aggregates and covers
                <b><i><u>multiple countries, operators, and payment channels.</u></i></b></p>
            <p>Right now, our API manages the payment channels below : </p>
            <p>
                &#x2022; <b>Web </b>: channel dedicated to all payment traffic using our PopUp UI
            </p>
            <p>&#x2022; <b>TPE </b>: channel dedicated to all payment traffic coming from integrated merchant-TPE
                devices.
            </p>
            <p>&#x2022; <b>USSD</b> : channel dedicated to all payment traffic coming from USSD channel
            </p>
            <p>This API lets you target the right country, operator, and channel by using the dedicated
                Header for that.</p>
            <p>To grant access to the service, our Integration team will onboard you on this service/API and
                send you your dedicate credentials/access-Token.</p>
            <p>So far, only <b>Orange Money web-payment</b> page is available for testing via a sandbox.
                You can start your implementation based on this Sandbox</p>



              <br><br>

              <p>Below diagram call flow : </p>
              <img src="/assets/images/mobilemoneydiagram.png"  alt=""
              class="ui fluid image">
<br><br>


                <b>Note :</b> <br>
                <i>Please note that is necessary to book a slot for an end-to-end test on our sandbox. Our
                    Integration team (<code class="code">integration@bizao.com</code> ) will help you during the testing
                    step by providing
                    you a PIN and a dumb number to validate each payment test.
                    Each PIN is valid during 15 mns.</i>



            <p>This API manages three categories of parameters:</p>
            <p>&#x2192; Headers: contains information letting Bizao to route your traffic by : country,
                operator and channel targeted.</p>
            <p>&#x2192; Body-parameters: contains detail on your payment transaction: order-id, amount,
                currency, …</p>
            <p>&#x2192; Static-parameters: this category of parameter cover all parameter that are static
                per merchant for all its payment traffic (Notification-URL,…). These parameters will
                be provisioned in Bizao Hub (in SignUP step) per Merchant and will be used by Bizao-
                Hub in the payment traffic.</p>
            <br>

            <br>

<p class="first-wrapper-title">Your ACCESS_TOKEN</p>

<p>The ACCESS_TOKEN is generated and must be present in the header of all your calls to our
  APIs.</p>

  <p style="margin-left: 5%;">
    The ACCESS_TOKEN is generated and must be present in the header of all your calls to our
APIs.

<br>
      <b>Sample of ACCESS_TOKEN: </b>
      <em>“4qa1bae4-3f9b-346-9t8b-c0e4d4ef”</em>

  </p>

  <p>
    You will find more information about how to generate and how to use your ACCESS_TOKEN here:
<a href=" https://dev.bizao.com/#/get_access_token">https://dev.bizao.com/#/get_access_token</a>
  </p>

            <br>
            <br>

            <br>

            <p class="first-wrapper-title">
              2.Principles
          </p>

          <p>
            This API allows you to create payment transaction on Bizao Hub based on the information
provided in your request.
This API manages three categories of parameters:
          </p>

          <p style="margin-left: 5%;">
           <b> • Headers:</b>contains information letting Bizao to route your traffic by : country,
operator and channel targeted. <br>
<b> • Body-parameters:</b> contains detail on your payment transaction: order-id, amount,
currency, …<br>
<b> • Static-parameters: </b> this category of parameter cover all parameter that are static
per merchant for all its payment traffic (Notification-URL,…). These parameters will
be provisioned in Bizao Hub (in SignUP step) per Merchant and will be used by BizaoHub in the payment traffic.

          </p>

            <p class="first-wrapper-title">
                3. Description
            </p>
            <p>Bizao &#x2039;mobileoney&#x203A; API is a one-time API. Below the syntax and description :
            </p>
            <p>&#x2022; <span class="light-span">Api-name</span> : <span class="code"><code>“mobilemoney/v1”</code></span></p>
            <p>&#x2022; <span class="light-span">Based URL PREPROD</span> : <span class="code"><code> https://preproduction-gateway.bizao.com</code></span></p>
            <p>&#x2022; <span class="light-span">Based URL PROD</span> : <span class="code"><code>  'https://api.bizao.com'</code></span></p>
            <p>&#x2022; <span class="light-span">METHOD</span> : <span class="code"><code>POST</code></span></p>


            <br>

            <p>To call the “mobilemoney” service, below is the syntax:</p>

            <p><span class="light-span">PREPROD</span> : <span class="code"><code> https://preproduction-gateway.bizao.com</code></span></p>
            <p><span class="light-span">PROD</span> : <span class="code"><code>  'https://api.bizao.com'</code></span></p>


            <p><b>3.1.  “mobilemoney” API query syntax </b></p>

            <p><b>Your query will contain the following Headers</b> : </p>
            <table>
                <tr>
                    <td><b>Header</b> </td>
                    <td><b>Description/Content </b> </td>
                    <td><b>Usage</b> </td>
                </tr>
                <tr>
                    <td>Authorization</td>
                    <td>YOUR_ACCESS_TOKEN </td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>mno-name</td>
                    <td>(string) the name of the operator.
                        example : orange, mtn. Bizao will update you
                        regarding the new mno set in the hub</td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>country-code</td>
                    <td>(string) characters code of the country.
                        Use this norme : ISO 3166 alpha 2 country code, url to
                        get the all country-code list:
                        <code>https://www.iban.com/country-codes</code>
                        <i>(for instance : is the country-code for ivory
                            Coast.)</i>
                    </td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>channel</td>
                    <td>The type of targeted channel using one of keyword: <br>
                        <b>&#x2039;web&#x203A;</b>: to target the Bizao payment PopUp <br>
                        <b>&#x2039;type&#x203A;</b>: to target the Bizao TPE payment flow <br>
                        <b>&#x2039;ussd&#x203A;</b>: to target the Bizao USSD payment flow <br>
                    </td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>lang</td>
                    <td>the abbreviation in 2 characters of targeted
                        language for the PopUp, In ISO 639 (alpha-2) format</td>
                    <td>
                        Mandatory
                    </td>
                </tr>
                <tr>
                    <td>content-type</td>
                    <td>application/json</td>
                    <td>Mandatory</td>
                </tr>
            </table>
            <br> <br>
            <p><b>The body of your query will manage parameters below</b>:</p>
            <br>
            <table>
                <tr>
                    <td><b>Parameter
                            name</b> </td>
                    <td><b>Description</b></td>
                    <td><b>Usage</b></td>
                </tr>
                <tr>
                    <td>currency</td>
                    <td>(string) currency identifier as defined in [ISO4217].
                        you can use this site to know the currency-code by
                        country: <code>https://fr.iban.com/currency-codes.html</code></td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>order_id</td>
                    <td>(string) unique identifier of the payment request.
                        <b>IT MUST BE UNIQUE FOR THE SYSTEM</b> and must follow
                        the following format: “MyMerchantNAme_ID” where:
                        ID : is a unique number identifier of transaction <br>
                      <b>PS: special characters should be avoided, order_id
                        should contain only letters and numbers</b></td>
                    <td>Mandatory 30 char max</td>
                </tr>
                <tr>
                    <td>amount</td>
                    <td>(decimal) amount to be charged.</td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>return_url</td>
                    <td>The URL of the web site where the customer returns
                        when the payment is completed</td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>cancel_url</td>
                    <td>The URL of the web site where the customer returns
                        when the payment is cancelled</td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>reference</td>
                    <td>Reference to the Merchant Name.</td>
                    <td>Mandatory 30
                        char max</td>
                </tr>
                <tr>
                    <td>user_msisdn</td>
                    <td>The MSISDN of the User/purchaser.
                        To use only in : TPE and USSD channels.</td>
                    <td>Optional in case
                        of WEB channel</td>
                </tr>
                <tr>
                    <td>otp_code</td>
                    <td>When applicable, the OTP Code is the one
                        generated by the User to approve the transaction.
                        To use only in : TPE and USSD channels</td>
                    <td>Optional /
                        Mandatory
                        based on the
                        operator</td>
                </tr>
                <tr>
                    <td>state</td>
                    <td>Parameter up to merchant to set within any value he
                        wants to keep over all payment transaction
                        processing.
                        This field must be in Encodeded-URL (Bizao do not
                        alternate/update this value and send it back within
                        payment response/notification)</td>
                    <td>
                        Mandatory
                    </td>
                </tr>
                <br>
            </table>
            <br>


        </div>
        <!-- End of first-wrapper -->


        <!-- Start of second wrapper -->
        <div class="second-wrapper section scrollspy" id="sample-queries">
            <div class="sample-wrapper">
                <span class="light-span">Payment query sample :</span> <br><br>
            <b>Note</b>: for each new payment-query you have to provide a new value for <b><i>“order_id”</i>.</b>
            <pre>
            <div class="code">
curl --location --request POST 'https://api.bizao.com/mobilemoney/v1' \
--header'Authorization: Bearer 483a05b2-25e8-314f-8db3-dc8081547458' \
--header'country-code: sn' \
--header'mno-name: orange' \
--header'lang: fr' \
--header'channel: web' \
--header'Content-Type: application/json' \
--data-raw '
&#123;
    "currency": "XOF",
    "order_id": "MerchantOrderid4",
    "amount": 10,
    "state": "param1%3Dvalue1%26param2%3Dvalue2",
    "return_url": "https://callback.bizao.com/MM/return.html",
    "cancel_url": "https://callback.bizao.com/MM/return.html",
    "reference": "Your-reference",
    “user_msisdn”:””:: it’s optional
&#125; '
            </div>
            </pre>
           <p><b>3.2.2 “mobilemoney” API response</b></p> <br><br>
            <p> Bizao mobilemoney payment API will response in Json format.
                This Json format/response has two different body according to targeted payment
                channel:</p>
            <p>&#x2192; <b><i>&#x2039;web&#x203A;</i></b> channel : </p>
            <p>In case the merchant targets the <b><i>&#x2039;web&#x203A;</i></b> channel, the payment API will
                send/response with the URL of Bizao PopUP as below
            </p>

            <p><b>Success query response sample</b></p>
            <pre>
            <div class="code">
Content-Type: application/json
&#123;
"status": 201,
"message": "OK",
"pay_token": "v1sqjbl4cahdoonenvedipechroz9yz1agvhxoxblnvyl2kngd4dcua4qyyma2kk",
"payment_url": "https://webpayment.orangemoney.com/ci/mpayment/abstract/v1sqjbl4cahdoonenvedipechroz9yz1agvhxoxblnvyl2kngd4d  cua4qyyma2kk",
"notif_token": "ibcukkpd2mgmahli4qgpig0w0hmhwbgi",
“state": "param1%3Dvalue1%26param2%3Dvalue2”,
"user_msisdn": " "
&#125;
            </div>
            </pre>
            <p><span class="light-span">pay_token</span>: uniquely identifies the payment page/PopUp.</p>
            <p><span class="light-span">payment_url</span> : URL of the Bizao PopUp payment where the user will process
                the
                payment (to display in the User device)</p> <br>
            <p>&#x2192; <b><i>&#x2039;ussd & tpe&#x203A;</i></b> channel : </p>
            In case the channel is one of value: &#x2039;ussd&#x203A; or &#x2039;tpe&#x203A;. The Bizao Hub response
            will be json
            format/content as below:
            <pre>
            <div class="code">
&#123;
    "meta": &#123;
        "type": "payment",
        "source": "orange",
        "channel": "tpe"
    &#125;
&#123;
        "status": "pending",
        "amount": "xxxxxx",
        "order-id": "xxxxxx",
        "currency": "xxxxx",
        "reference": "xxxx",
        "country-code": "xxxxx",
        "state": "xxxxxxxxxxx",
        "user_msisdn": "22575338226",
        "otp_code": "123467",
        " intTransaction-id": "1601372072771",
        "extTransaction-id ": "MP200929.0935.A64320"
    &#125;
&#125;
            </div>
            </pre>
            </div>
            <br><br><br>
            <p class="first-wrapper-title">3. Notification flow</p>
            <p><b>Bizao mobilemoney API</b> also manages a Notification flow. For each user payment
                transaction, Bizao makes two type of notification:</p>
            <p>&#x2192; <span class="light-span">B2C-Notif</span>: this category of notification is for the
                User/purchaser. For each successful
                payment, Bizao will send him a SMS. The format and wording of this SMS message
                depends on the Southbound/operator backend that Bizao uses.</p>
            <p>&#x2192; <span class="light-span">B2B-Notif</span>: this category of notification is for the merchant
                backend. For each
                payment transaction, Bizao will notify the merchant backend (using the merchant-
                Callback) with the final status of the payment transaction.</p>


                <p> <b>Note: </b>In case of timeout or any technical issues, “getStatus” API is another alternative
                  to get the transaction details.</p>

                  <br>
                  <p>Below a sample of notification content:
                  </p>

                  <pre>
                    <div class="code">
&#123;
  "meta": &#123;
  "type":"payment-notif",
  "source": "orange",
    "channel": "web"
  &#125;
    &#123;
  “status” : “successful”,
  “amount” : “xxxxxx”,
  “order-id” : “xxxxxx”,
  “currency”: “xxxxx”,
  “reference” : “xxxx”,
  “country-code”: “xxxxx”,
  “state”: ”xxxxxxxxxxx”,
  “user_msisdn”:”22575338226 “,
    " intTransaction-id": "1601372072771",
    "extTransaction-id ": "MP200929.0935.A64320"
    &#125;
  &#125;
                    </div>
                  </pre>
        </div>
        <br>
        <!-- End of second wrapper -->

        <!-- Start of third wrapper -->

        <!--
        <div class="third-wrapper section scrollspy" id="errors">
            <p class="first-wrapper-title">
                4. Available Country and Operator
            </p>
            <p>Below the list of country and MNO where the Mobile Money service is available for Bizao.
                Within this table you will find the required code for country and operator to use in the
                mobilemoney query call.</p>
                <table>
                    <tr>
                        <td><b>country</b></td>
                        <td><b>Operator (mno-
                            name)</b></td>
                        <td><b>country-code</b></td>
                        <td><b>currency</b></td>
                    </tr>
                    <tr>
                        <td>Ivory Coast</td>
                        <td>moov</td>
                        <td>ci</td>
                        <td>XOF</td>
                    </tr>
                    <tr>
                        <td>Ivory Coast</td>
                        <td>Orange</td>
                        <td>ci</td>
                        <td>XOF</td>
                    </tr>
                    <tr>
                        <td>Burkina Faso</td>
                        <td>orange</td>
                        <td>bf</td>
                        <td>XOF</td>
                    </tr>
                    <tr>
                        <td>Senegal</td>
                        <td>orange</td>
                        <td>sn</td>
                        <td>XOF</td>
                    </tr>
                    <tr>
                        <td>Senegal</td>
                        <td>free</td>
                        <td>sn</td>
                        <td>XOF</td>
                    </tr>
                    <tr>
                        <td>Cameroon</td>
                        <td>orange</td>
                        <td>cm</td>
                        <td>XAF</td>
                    </tr>
                </table>
                <br><br>
            <p class="first-wrapper-title">5. Bizao payment API common error code</p>
         <table>
             <tr>
                 <td><b>ErrorCode</b> </td>
                 <td><b>Exception Message</b> </td>
                 <td><b>Description / Comments</b> </td>
                 <td><b>HTTP response code</b></td>
             </tr>
             <tr>
                 <td>50</td>
                 <td>Forbidden access to the API</td>
                 <td>Access denied by ACL. <br>
                    <code class="code">'Unauthorized Access Layer'</code> or <br>
                    <code class="code">'Unauthorized applicationId'</code> or <br>
                    <code class="code">‘Unauthorized country</code></td>
                 <td>403
                    Forbidden</td>
             </tr>
             <tr>
                 <td>1201</td>
                 <td>Forbidden access to the API</td>
                 <td>Forbidden transaction</td>
                 <td>403
                    Forbidden</td>
             </tr>
             <tr>
                 <td>1202</td>
                 <td>Forbidden access to the API</td>
                 <td>Invalid merchant key</td>
                 <td>403
                    Forbidden</td>
             </tr>
             <tr>
                 <td>1203</td>
                 <td>Forbidden access to the API</td>
                 <td>Unauthorized currency for this
                    country</td>
                 <td>403
                    Forbidden</td>
             </tr>
             <tr>
                 <td>1204</td>
                 <td>Forbidden access to the API</td>
                 <td>Order Already exists. The
                    <b><i>order_id</i></b> must be unique in the
                    system. <br> Only one <b><u>Token per
                        order_id</u></b></td>
                 <td>403
                    Forbidden</td>
             </tr>
         </table>

        </div>
         End of third wrapper -->


    </div>

</div>
