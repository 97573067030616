<p class="first-wrapper-title section">Validate Challenge API</p>

<p>To validate Challenge you will use:</p>

<p style="margin-left: 5%;">
- Bizao-URI: <a href="#">/challenge/v2/challenges/challenge ID </a> <br>
- Bizao-Host: <a href="#"> api.bizao.com </a>
</p>

<p>
<b> Note :</b> You will use challenge-id generated in response to Subscription API.
Below headers of the request:
</p>

<table>
  <tr>
      <td><b>Header</b></td>
      <td><b>Description/Content</b></td>
  </tr>
  <tr>
    <td>Content-Type</td>
    <td>application/json</td>
  </tr>
  <tr>
      <td>Authorization</td>
      <td>YOUR_ACCESS_TOKEN</td>
  </tr>
  <tr>
      <td>mno-name</td>
      <td>(string) the name of operator you target. Example :
        <b>orange, mtn</b>. <br> Bizao will update you regarding the new
        mno set in the hub.</td>
  </tr>
  <tr>
      <td>country-code</td>
      <td>(string) 2 characters code of the country you target
        ( use this norme : <br> ISO 3166 alpha 2 country code, url to
        get the all country-code list : <br>
        <pre><code>https://www.iban.com/country-codes</code></pre> (for example CI is
        the country-code for ivory Coast.)</td>
  </tr>
  <tr>
    <td>product-id</td>
    <td>(alphanum) the Id related to your Product ( will be
      provided by Bizao integration team in service
      Onboarding step )
       <br>
      </td>
</tr>
<tr>
  <td>SP-id</td>
  <td>(alphanum) the Id related to you per country ( will be
    provided by Bizao integration team in service
    Onboarding step )
     <br>
    </td>
</tr>

</table>

<p>
  The body of the query will contain the parameters below into a “challenge” object (JSON):
</p>

<table>
  <tr>
      <td><b>Parameter</b></td>
      <td><b>Usage</b></td>
      <td><b>Description</b></td>
  </tr>
  <tr>
    <td>method</td>
    <td>Mandatory</td>
    <td>Always: OTP-SMS-AUTH</td>
  </tr>
  <tr>
      <td>inputs</td>
      <td>Mandatory</td>
      <td>JSON array of  &#123;key,value &#125; entries containing all the necessary <br>
        inputs to perform the challenge: <br>
        - MSISDN : end-user MSISDN <br>
        - confirmationCode: the OTP received by the end-user <br>
        - info: always “msisdn” as value
      </td>
  </tr>


</table>

<div class="sample-wrapper">
  <pre>
<div class="code" >
  curl -i -X POST \
https://api.bizao.com/challenge/v1/challenges/5bc5f37f7370537a0fb9f0fc \
-H 'authorization: Bearer YOUR_ACCESS_TOKEN' \
-H 'cache-control: no-cache' \
-H 'content-type: application/json' \
-H 'country-code: cm' \
-H ‘mno-name: mtn' \
-H 'sp-id: 237XXXXXXXXX' \
-H 'product-id: 237XXXXXXXXXX' \
-d '
 &#123;
"challenge":  &#123;
"method": "OTP-SMS-AUTH",
"inputs": [ &#123;
"type": "MSISDN",
"value": "+22500000000"
 &#125;,
 &#123;
"type": "confirmationCode",
"value": "9683"
 &#125;,
 &#123;
"type": "info",
"value": "msisdn"
 &#125;
]
 &#125;
 &#125;
        </div>
        </pre>
        </div>

        <p>Success validate challenge response</p>

        <div class="sample-wrapper">
          <pre>
        <div class="code" >
           &#123;
            "subscription-id": "80244c30-05be-48bf-9b06-bedbcfd526e3",
            "subscription-status": "Successful",
            "user-id": "237680975147",
            "sp-id": "2370110012321",
            "product-id": "23701220000029108",
            "periodicity": "1 day",
            "amount-charged": "150.00000",
            "country-code": "cm",
            "currency": "XOF",
            "Sub-startdate": "2021-01-19 11:17:07.0",
            "mno-name": "mtn",
            "state": "param1%3Dvalue1%26param2%3Dvalue2kjlasdlkfjsdfsdfasdfasdf"
             &#125;
                </div>
                </pre>
                </div>


<br>

