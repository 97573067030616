<div class="row">
  <div class="col s12 m10 l10">

    <!-- Start of first wrapper -->


    <div class="first-wrapper section scrollspy" id="generate-challenge">

      <br>
      <!-- Start of Principles Section -->
      <p class="first-wrapper-title">Introduction</p>

      <p class="first-wrapper-title sidestepA">1.1 Document Purpose</p>
      <p>
        This document covers, in details, the design of VISA and MasterCard API renamed <span class="light-span">debitCard</span>. It contains the implementation and specification details of the service, which are
        useful for the NorthBound interface developers. <br>
        It describes how to payment enable an e-commerce application or online store by using
the functionality of the MasterCard Virtual Payment Client (VPC) through Bizao third party
as a bridge.
        <br>
        Even if there are no samples for the 3DS card, all the information and specifications provided
here are applicable for both 3DS and non 3DS cards.

      </p>

      <p class="first-wrapper-title sidestepA">1.2 Abbreviations and Definitions</p>

      <br>
      <table>
        <thead  class="headerStyle">
          <td><b>Term</b> </td>
          <td><b>Definition</b> </td>

        </thead>
        <tr>
          <td>VPC</td>
          <td>Virtual Payment Client</td>
         </tr>
         <tr>
          <td>3DS</td>
          <td>3D Secure algorithm</td>
         </tr>
         <tr>
          <td>SP</td>
          <td>Service Providers</td>
         </tr>
      </table>
      <!-- End of Principles section -->

      <!-- Start of Generate challenge section -->
      <br>

      <p class="first-wrapper-title sidestepA">1.3 Dependencies and Assumptions</p>

      <p>
        Merchant should have a valid Auth2 account and is classified in a valid category. <br>
        Merchant should provide a returnUrl for redirection after a valid payment. <br>
        Merchant should provide a cancelUrl to be called when the customer cancels a payment
before its processing. <br>
Merchant should provide a callBackUrl for the B2B notifications between Bizao and the
merchant for any processed transaction. <br>

      </p>

      <p class="first-wrapper-title sidestepA">1.4 debitCard</p>

      <p>
        The API is a RESTful Web Services with JSON formatted messages. All requests need to set the
header Content-type as "application/json".
      </p>

      <p>
        The north endpoint is:  <span class="light-span"> /debitCard </span> and the communication Protocol is HTTPS standards.
The complete url of the service for the merchant is :
        <p class="sidestepA">
          &#x2022; <b>Preproduction </b> : <a href="https://preproduction-gateway.bizao.com/debitCard/v2/ ">https://preproduction-gateway.bizao.com/debitCard/v2/</a>
          <br>
          &#x2022; <b>Production </b> : <a href="https://api.bizao.com/debitCard/v2/">: https://api.bizao.com/debitCard/v2/</a>

        </p>

        <p>
          POST method is used anywhere it is applicable. In order to accomplish a Basic 3-Party
Transaction through BIZAO debitCard service, below are the minimal parameters you need
to setup in the request: <br>

The Bizao Northbound debitCard API/solution is multi-country and multi-language.
Mandatory header parameters are: “content-Type”, “authorization”,“country-code”,”
category” & “lang” (for this version of the API, you must always set the value as “en”).

        </p>




      <p class="first-wrapper-title">2.Merchant onboarding </p>

      <p>To grant access to the service, our Integration team will do an offline onboarding of the
          merchant and send the dedicate credentials/access-Token.</p>
          <p>
            The ACCESS_TOKEN is generated thanks to your CLIENT_ID and CLIENT_SECRET and must be
            present in the header of all your calls to our APIs.
          </p>

            <br>
            <p class="sidestepA">
                <b>Sample of ACCESS_TOKEN: </b>
                <i>“4qa1bae4-3f9b-346-9t8b-c0e4d4ef”</i>
            </p>
            <br>
            <p>
              You will find more information about how to generate and how to use your ACCESS_TOKEN
              here: <a href="https://dev.bizao.com/#/get_access_token">https://dev.bizao.com/#/get_access_token</a>
            </p>


      <br>



      <p class="first-wrapper-title">3. &#60;debitCard&#62; API</p>

      <p  class="first-wrapper-title sidestepA"> 3.1 Principles</p>

      <p>
        This API allows you to create payment transaction on Bizao Hub based on the information
        provided in your request.
        <br>
        This API manages three categories of parameters:
      </p>
      <p class="sidestepB">
        &#x2022; <b> Headers :</b> contains information letting Bizao to route your traffic by: country and
        language you target.
        <br>

        &#x2022; <b> Body-parameters :</b> contains detail on your payment transaction: amount, currency,
        used-id…
        <br>
        &#x2022; <b>Static-parameters :</b> this category of parameter cover all parameters that are static
        per merchant for all its payment traffic (Notification-URL, category …). These
        parameters will be provisioned in Bizao Hub (in SignUP step) per Merchant and will
        be used by Bizao-Hub in the payment traffic.

      </p>

      <p  class="first-wrapper-title sidestepA"> 3.2 Description</p>

      <p> Bizao &#60;debitCard&#62; is a one-time API, below syntax and descriptive </p>
      <br>
      <p class="sidestepB">
        &#x2022; <b>Api-name</b>: “debitCard” <br>
        &#x2022; <b>URL</b>:
        <br>
        <span class="sidestepC">
          &#9675; &nbsp;<b>Preproduction </b>: <a href="https://preproduction-gateway.bizao.com/debitCard/v2">https://preproduction-gateway.bizao.com/debitCard/v2</a>
        </span>
          <br>
          <span class="sidestepC">&#9675; &nbsp;<b>Production </b>: <a href=" https://api.bizao.com/debitCard/v2"> https://api.bizao.com/debitCard/v2 </a> </span>
        <br>
        &#x2022; <b>Method</b>: Post

      </p>
      <p class="bold sidestepB">
        3.2.1 debitCard API query syntax
      </p>

      <p class="sidestepA">
        Your query will contain the following Headers:

      </p>

      <table>
        <tr>
          <td><b>Header</b> </td>
          <td><b>Description/Content</b> </td>
          <td><b>Usage</b> </td>
        </tr>
        <tr>
          <td>Authorization</td>
          <td>YOUR_ACCESS_TOKEN</td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>country-code</td>
          <td>(string)2 characters code of the country you target
            ( use this norme : ISO 3166 alpha 2 country code, url
            to get the all country-code list:
            https://www.iban.com/country-codes
            (for instance:&#60;ci&#62; is the country-code for ivory Coast.</td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>lang</td>
          <td>the abbreviation in 2 characters of targeted
            language for the request, In ISO 639 (alpha-2) format. For this version
            of the debitCard service, only English language (en) is
            accepted </td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>channel</td>
          <td>The type of targeted channel using one of keyword:
            &#60;web&#62;: </td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>content-type </td>
          <td>application/json</td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>category </td>
          <td>The category of the merchant. </td>
          <td>Mandatory</td>
        </tr>
      </table>

<br>

<p class="sidestepA">The body of your query will manage parameters below:</p>

      <table>
        <tr>
          <td><b>Parameter name</b> </td>
          <td><b>Description</b> </td>
          <td><b>Usage</b> </td>
        </tr>
        <tr>
          <td>currency</td>
          <td>(string) currency identifier as defined in [ISO4217]. Note (as
            described for the amount parameter) that either currency
            and amount or code must be specified. you can use this site
            to know the currency-code by country:
            <br>

            https://fr.iban.com/currency-codes.html
          <br>
          For this version of the service, only XOF currency is accepted.</td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>order_id</td>
          <td>(string) identifies this create payment request. This field
            SHOULD be present. <b>IT MUST BE UNIQUE FOR THE SYSTEM</b> and
            must follow the following format: “MyMerchantNAme_ID”
            where: ID : is a unique number identifier of transaction </td>
          <td>Mandatory 30 char max</td>
        </tr>
        <tr>
          <td>amount</td>
          <td>tamount to be charged. Decimal value is not accepted</td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>return_url </td>
          <td>TThe URL of the web site where the customer returns when the
            payment is completed</td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>cancel_url</td>
          <td>The URL of the web site where the customer returns when the
            payment is canceled by the customer</td>
          <td>Mandatory</td>
        </tr>
        <tr>
          <td>reference</td>
          <td>Reference to the Merchant Name</td>
          <td>Mandatory
            30 char max</td>
        </tr>
        <tr>
          <td>state</td>
          <td>Parameter up to merchant to set within any value he wants
            to keep over all payment transaction processing; this field
            must be in Encodeded-URL (Bizao do not alternate/update
            this value and send it back within payment
            response/notification)
            </td>
          <td>Mandatory
            30 char max</td>
        </tr>
      </table>

      <p class="greyB">
        <b><u>Note:</u></b> the amount doesn’t accept cents. So, 1.00XOF is considered to be 100XOF
      </p>

      <p>
        <b>Payment query sample</b>
        <br>

        <b>Note : </b> for each new payment-query you have to provide a new value for “order_id”
        Parameter
      </p>

      <p class="sidestepB"><b> 3.2.2 debitCard API response </b></p>

      <p>
        Bizao debitCard API will response in Json format
        The debitCard API will send/response with the URL of Bizao web payment page as
        below:
      </p> <br>
     <b> Success query response Sample </b>

     <pre>
      <div class="code" >
        Content-Type: application/json
        &#123;
         "status": "201",
         "message": "OK",
         "order_id": "Transction Id of the merchant”
         "state": "test CM",
         "payment_url": "https://preproduction-visamc.bizao.com/visa-mc/7e
         07e4d4-a3fd47a4-8d1e-360c6647bbab"
         &#125;

      </div>

    </pre>

    <p>
      &#9675; &nbsp; <b>status </b> uniquely identifies the transaction. Status of the response <br>
      &#9675; &nbsp; <b>message:</b> Message of the response <br>
      &#9675; &nbsp; <b>order_id:</b> Transaction id of the partner <br>
      &#9675; &nbsp; <b>payment_url :</b> URL of the Bizao payment page where the user will enter his
State: the correlation-Id sent by the merchant <br>
&#9675; &nbsp; <b>State:</b> the correlation-Id sent by the merchant
    </p>

    <p  class="first-wrapper-title sidestepA"> 3.3 Notification flow
    </p>

    <p>
      Bizao debitCard API also manages a Notification flow. For each user payment transaction,
      Bizao makes two type of notification:
    </p>
    <p class="sidestepA">

      &#9675; &nbsp; <b>B2C-Notif :</b> this category of notification is for the Users/purchaser. For this service, BIZAO
      is not handling a B2C notification. For a successful payment, the customer may
      expect an SMS from his bank provider based on the customer service.
       <br>

       &#9675; &nbsp; <b>B2B-Notif :</b> tthis category of notification is for the merchant backend. for each
       payment transaction, Bizao will notify the merchant backend (using the merchantCallback) with the final status of the payment transaction. Below a sample of
       notification content:
       <br>


    </p>

    <pre>
      <div class="code" >
         &#123;
          "meta": &#123;
          "type":"payment ",
          "source": "debitCard",
           "category": "education"
           &#125;

           &#123;
          “status” : “successful”,
          “amount” : “xxxxxx”,
          “order-id” : “xxxxxx”,
          “currency”: “xxxxx”,
          “reference” : “xxxx”,
          “country-code”: “xxxxx”,
          "date": "2020-12-08 10:35:00.0",
          "state": "xxxxxxxx",
          "intTransaction-id": "xxxxxxxxxxx",
           "extTransaction-id": "xxxxxxxxxx"
           &#125;
          &#125;

      </div>

    </pre>

    <br><br>
    <p class="first-wrapper-title">4 Accepted Cards</p>

  <p> Below the list of cards accepted in Bizao debitCard service</p>

  <table>
    <tr>
      <td><b>Card type</b> </td>
      <td><b>Currency</b> </td>
    </tr>
    <tr>
      <td>Visa</td>
      <td>XOF</td>
    </tr>
    <tr>
      <td>Mastercard</td>
      <td>XOF</td>
    </tr>
  </table>






    <!-- End of fourth wrapper -->


</div>
