<div class="row">
    <div class="col s12 m10 l10">

        <!-- Start of first wrapper -->
        <div class="first-wrapper section scrollspy">
            <p class="first-wrapper-title">MTN unsubscription notification</p>
            <br>
            <p class="first-wrapper-title">1. Principles</p>
            <p>Bizao will also notify the SP’s backend for each successful UnSubscription made per user.

                Bizao will use a dedicated meta-data for this notification, that meta data will let you know :</p>
                <p> &#x2022;&nbsp; <span class="light-span"><b>The meta data will let you know .</b></span>:</p>
            <p>&#x2192;<b><i>The type of notification according to each flow</i></b>: <code class="code">&#x2039;subscription&#x203A;, &#x2039;unsubscription&#x203A;
                or &#x2039;renewal&#x203A;.</code></p>
            <p>&#x2192;&nbsp;The &#x2039;<b><i>MNO</i></b>&#x203A; source of this notification.</p>
<br>

            <p>Notification Body with fields below: < you can correlate with MSISDN & Product-ID > </p>

              <p style="margin-left: 10%;">

<b>-UnSubscription-id:</b> a unique ID per unsubscription ( sample : "55561763-6425-441x-95ef34a36971df5c"), <br>
  <b>- Unsubscription-status:</b> the status of the Unsubscription query ( sample : "Completed" ), <br>
    <b>- Subscription-id:</b> a reminder of the Subscription-ID you are UnSubscribing ( sample :
"44561763-2225-441x-933f-34a3690965c",<br>
<b>- Unsubscription-timestamp:</b> the real time of Unsubscription commitment (sample :
"2019-11-28 15:27:08.000"),<br>
<b>- user-id":</b> the MSISDN of the user unsubscribed <br>
  <b>- product-id":</b> the Product ID you target ( sample "22501220000029439"), <br>
    <b>- "service-id":</b> your Service ID you got from Bizao ( its unique per SP"), <br>
      <b>- "country-code":</b> the country you target for this unsubscriptoion ( sample "ci" for Ivory
Coast)<br>
<b>- "state": </b> the correlation data you sent in the subscription quey, <br>

              </p>

            <p><b>&#x2022; Sample of the full notification response </b></p>
              <pre>
                <div class="code">
&#123;
    "meta": &#123;
        "type": "unsubscription - notif",
        : "mtn"
    &#125;,
    "UnSubscription - id": "55564763-6425-441d-95ef-34a36971df5c",
    "Unsubscription - status": "Completed",
    "Subscription - id": "41e8fde7-d7e4-4967-b078-d3f17c193030",
    "Unsubscription - timestamp": "2019-11-28 15:27:08.000",
    "user - id": "2257533xxx",
    "service - id": "225012000025033",
    "product - id": "22501220000029439",
    "country - code": "ci",
    "state": "xxx"
&#125;
                </div>
                </pre>

        </div>
        <!-- End of first wrapper -->
    </div>



    <div class="col hide-on-small-only  s12 m2 l2">

    </div>
</div>
