<div class="first-wrapper">
    <p class="first-wrapper-title">Bizao API documentation</p>
    <p>Welcome to the Bizao developer documentation where you will learn how to create incredible payment experiences
        with the Bizao APIs.
    </p>
    <div class="">
        <div class="row first-wrapper-content">
            <div class="col s12 m6 l6 first-wrapper-paragraph-parent">
                <p class="first-wrapper-paragraph">Because we listen to your needs we strive to provide you with detailed technical documentation that will
                    give you the opportunity to understand the operation of our APIs and make integrations easily and
                    straightforward.</p>
            </div>
            <div class="col s12 m6 l6 separator_mobile">
                <img src="/assets/images/cardHomePage.png" width="350px" height="10px" alt=""
                    class="cardHomepage ui fluid image">
            </div>
        </div>
    </div>

</div>


<div class="separator"></div>


<div class="second-wrapper">
    <p class="second-wrapper-title">
        Easy and fast Integration
    </p>
    <p>
        Our development team helps you integrate our APIs as simply as possible and remains at your disposal in case of need. </p>
    <div class="row second-wrapper-row">
        <div class="col s12 m4 l4 separator_mobile">
            <img src="../../../assets/images/web-programming.svg" alt="">
            <span class="second-wrapper-subtitle">Software Development Kit</span>
            <p>We provide your developers with full SDKs for quick integration.</p>
        </div>
        <div class="col s12 m4 l4 integration-boxes separator_mobile">
            <img src="../../../assets/images/espace-developpeur.svg" alt="">
            <span class="second-wrapper-subtitle">Developers Space</span>
            <p>We give you the opportunity to test our APIs</p>
        </div>
        <div class="col s12 m4 l4 integration-boxes separator_mobile">
            <img src="../../../assets/images/security.svg" alt="">
            <span class="second-wrapper-subtitle">Security</span>
            <p>Your transactions are fully encrypted and filtered by the 3DS protocol for credit card payments.</p>
        </div>
    </div>
</div>


<div class="separator"></div>

<div class="third-wrapper">
    <div class="row">
        <div class="col s12 m8 l8">
            <p class="third-wrapper-title">Offers</p>
            <p>Bizao puts at your disposal several solutions allowing you to launch your activities in complete security.</p>
            <div class="row offer-wrapper">
                <div class="col s12 m4 l4 dcb-offer separator_mobile">
                    <img src="../../../assets/images/dcb.svg" alt="">
                    <span class="third-wrapper-subtitle ">Direct Carrier Billing</span> <br>

                    <p class="service-description"> Monetize your digital content in a single action.</p>
                </div>
                <div class="col s12 m4 l4 separator_mobile">
                    <img src="../../../assets/images/transfertdargent.svg" alt="">
                    <span class="third-wrapper-subtitle">Money transfert</span>
                    <p class="service-description"> Transfer money directly to your loved ones from your Mobile Money account</p>
                </div>
                <div class="col s12 m4 l4 separator_mobile">
                    <img src="../../../assets/images/paiementmarchand.svg" alt="">
                    <span class="third-wrapper-subtitle">Merchant payment</span>
                    <p class="service-description"> Carry out your transactions online from your Mobile Money account or your credit card</p>
                </div>

            </div>
        </div>
        <div class="col s12 m4 l4 ">
            <p class="third-wrapper-title">Plugins</p>
            <p>Integrate our APIs into your web application and e-commerce projects thanks to our plugins.</p>
            <div class="plugins-section">
                <div class="col s4 m4 l4"> <p><a class="modal-trigger" href="#woocommerce"><img src="../../../assets/images/wordpress.svg" alt=""><b>Wordpress</b></a></p></div>
                <div class="col s4 m4 l4"><p><a class="modal-trigger" href="#prestashop"><img src="../../../assets/images/prestashop.svg" alt=""><b>Prestashop</b></a></p></div>
                <div class="col s4 m4 l4"><p><a class="modal-trigger" href="#joomla"><img src="../../../assets/images/joomla.svg" alt=""><b>Joomla</b></a></p></div>
            </div>

        </div>
    </div>
</div>


<div id="woocommerce" class="modal">
    <div class="modal-content">
        <h4>WooCommerce Plugin </h4>
        <p>
        You're about to download the Bizao Woocommerce Plugin for Wordpress. <br>
        </p>
        <div class="modal-footer download-button">
          <a href="https://wordpress.org/plugins/bizaopay-for-woocommerce/" target="_blank" class="modal-close" >Download BizaoPay Plugin for Woocommerce</a>
        </div>
    </div>
</div>

<div id="prestashop" class="modal">
  <div class="modal-content">
    <h4>PrestaShop Plugin </h4>
    <p>
    The Bizao Module for PrestaShop will be available soon. <br>
    </p>

</div>
</div>

<div id="joomla" class="modal">
    <div class="modal-content">
        <h4>Joomla Plugin</h4>
        <p>
            The Bizao Plugin for Joomla will be avaibable soon. <br>
        </p>
    </div>
</div>
