import { Component, OnInit, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FetchDataService } from '../../../services/fetch-data.service';
declare var jQuery: any;
@Component({
  selector: 'app-mobile-money-api',
  templateUrl: './mobile-money-api.component.html',
  styleUrls: ['./mobile-money-api.component.css']
})
export class MobileMoneyApiComponent implements OnInit {

  constructor(private fetchDataService : FetchDataService) { }


  //find 5 day before date .
// Source: https://stackoverflow.com/a/1296374 .

key=""

  changeToPayAPI()
  {
    this.key="payapi"
  }

  changeToGetStatAPI()
  {
    this.key="getstatapi"
  }



  ngOnInit(): void {

    this.key="payapi"

    var d = new Date();

    console.log(d.toLocaleDateString())
 d.setDate(d.getDate()-5);

 console.log(d.toLocaleDateString())


  }

}
