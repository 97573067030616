import { Component, OnInit } from '@angular/core';
import {FetchDataService} from '../../../services/fetch-data.service';

@Component({
  selector: 'app-dcb',
  templateUrl: './dcb.component.html',
  styleUrls: ['./dcb.component.css']
})
export class DcbComponent implements OnInit {

  constructor(private fetchDataService : FetchDataService) { }


  //find 5 day before date .
// Source: https://stackoverflow.com/a/1296374 .



  key = this.fetchDataService.dcb_key;
  changeKeyToGenOTP() {
    this.key = "genOTP";
  }
  changeKeyToValOTP() {
    this.key = "valOTP";
  }
  changeKeyToPay() {
    this.key = "pay";
  }


  changeKeyToNotif() {
    this.key = "notif";
  }





  ngOnInit(): void {

    var d = new Date();

    console.log(d.toLocaleDateString())
 d.setDate(d.getDate()-5);

 console.log(d.toLocaleDateString())


  }

}
