<div class="row">
    <div class="col s12 m10 l10">
        <div class="first-wrapper section scrollspy" id="principles">
            <p class="first-wrapper-title">Mobile Money GetStatus API</p>
            <br>
            <p class="first-wrapper-title">1. Principles</p>
            <p>The merchant can check any transaction details via the "getstatus" service.</p>
            <p class="first-wrapper-title">2. Description</p>
            <p>
                <span class="light-span">Bizao getStatus</span> is a one-time API. Below syntax and description.
            </p>
            <p>&#x2022; <span class="keys">api-name</span> : <code class="code">"mobilemoney/getstatus/v1"</code> </p>
            <p>&#x2022; <span class="keys">order-id</span> : <code class="code">the order-id from the merchant</code></p>
            <p>&#x2022; <span class="keys">base-url</span> : <code class="code">https://api.bizao.com/</code></p>
            <p>&#x2022; <span class="keys">method</span> : <code class="code">GET</code></p> <br>
            <div class="sample-wrapper">
                <br>
                <p><b>&#x2192; getStatus API query sample : </b></p>
                <pre>
            <div class="code">
curl --location --request GET 'https://api.bizao.com/mobilemoney/getstatus/v1/Order_id' \
--header'Authorization: Bearer 483a05b2-25e8-314f-8db3-dc8081547458' \
--header'Accept: application/json' \
--header'Content-Type: application/json' \
--header'country-code: sn' \
--header'mno-name: orange' \
            </div>
            </pre>
            <div class="second-wrapper section scrollspy" id="samples">
                <p><b>&#x2192; getStatus API response sample : </b></p>
                <pre>
            <div class="code" id="sample_response_code">
 &#123;
    "meta": &#123;
        "type": "payment",
        "source": "sn_orange_mm",
        "channel": "web"
    &#125;,
    "status": "Successful",
    "amount": "10",
    "order-id": "Merchant_Order_id_4",
    "currency": "XOF",
    "reference": "Your-reference",
    "date": "2020-09-29 09:34:32.0",
    "state": "param1%3Dvalue1%26param2%3Dvalue2",
    "country-code": "sn",
    "intTransaction-id": "1601372072771",
    "extTransaction-id ": "MP200929.0935.A64320"
  &#125;
            </div>
            </pre>
        </div>

            </div>
            </div>
    </div>
    <div class="col hide-on-small-only s12 m2 l2">
        <ul class="section table-of-contents">
            <li>
                <p class="toc-title">On this page</p>
            </li>
            <li><a href="#principles">Principles</a></li>
        </ul>
    </div>
</div>
