import { Component, OnInit } from '@angular/core';
import { FetchDataService } from 'src/app/services/fetch-data.service';
declare var jQuery: any;


@Component({
  selector: 'app-money-transfert-gestatus',
  templateUrl: './money-transfert-gestatus.component.html',
  styleUrls: ['./money-transfert-gestatus.component.css']
})
export class MoneyTransfertGestatusComponent implements OnInit {

  constructor(private fetchDataService : FetchDataService) { }
  switchToMobileMoneyPayment(){
    this.fetchDataService.tab='mm';
    this.fetchDataService.mm_key = 'mm-pay-api';
  }
  ngOnInit(): void {

    (function ($) {

      $(document).ready(function(){
       $('.scrollspy').scrollSpy();
       $('.row .table-of-contents').pushpin({ top: 80 });
     });
   })(jQuery);

  }

}
