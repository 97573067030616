import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FetchDataService } from 'src/app/services/fetch-data.service';
declare var jQuery: any;

@Component({
  selector: 'app-money-transfer-get-status-api',
  templateUrl: './money-transfer-get-status-api.component.html',
  styleUrls: ['./money-transfer-get-status-api.component.css']
})
export class MoneyTransferGetStatusAPIComponent implements OnInit, AfterViewInit {
  req_headers:any=null;
  req_body: any = null;
  res_headers:any=null;
  res_body: any = null;
  pulse: boolean = true;
  res_header_active:boolean=false;



  constructor(private fetchDataService: FetchDataService) {

    this.req_headers={
      "authorization": "cb422427-1wo6-3be2-b15b-sff651s7bs4e",
    }

    this.req_body="No request Body"

  }

  sendRequest() {

this.res_headers="There are no headers in the response"

this.res_body=
  {
    "meta": {
      "type":"transfer-notif",
      "source": "sn-free",
       "channel": "web"
    },

      "status" : "successful",
      "amount" : "xxxxxx",
      "order-id" : "xxxxxx",//The unique id from the IRT
      "currency": "xxxxx",
      "reference" : "xxxx",
      "country-code": "xxxxx",
      "state": "xxxxxxxxxxx",
       "user_msisdn":"22575338226 ",
       "fromCountry":"fr ",
       "toCountry":"sn ",
       "intransactionId": "",  //the transaction id from bizao system
       "extransactionId": "",  //the transaction id from the operator
       "senderFirstName": "A sender name",
       "senderLastName": "A sender last name",
       "senderAddress": "A sender address",
       "senderMobileNumber": "33761234567",
       "beneficiaryFirstName": "Ibou",
       "beneficiaryLastName": "Fall",
       "beneficiaryAddress": "Medina",
       "beneficiaryMobileNumber": "221769876543",

  }

    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {

        console.log(response[0])
        if(response[0].doc.response.success.header.length == 0) {
          this.res_headers= "There are no headers in the response";
          this.res_body = response[0].doc.response.success.body;
        }
        else if(response[0].doc.response.success.body.length == 0) {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = "There is no body in the response";
        }
        else {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = response[0].doc.response.success.body;
        }
      }
    );
    */
    this.res_header_active = true
  }

  togglePulse() {
    this.pulse = false;
  }

  ngOnInit(): void {
    (function ($) {
      $(document).ready(function () {
        $('.tabs').tabs();
      });

      $(document).ready(function () {
        $('.scrollspy').scrollSpy();
      });
    })(jQuery);



  }

  ngAfterViewInit() {
    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {
        console.log(response[0])
        this.req_headers=response[0].doc.request.header;
        this.req_body=response[0].doc.request.body;
      }
    );
    */
  }


}
