import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-mtn-he-authentication',
  templateUrl: './mtn-he-authentication.component.html',
  styleUrls: ['./mtn-he-authentication.component.css']
})
export class MtnHeAuthenticationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
    (function ($) {
      
      $(document).ready(function(){
       $('.scrollspy').scrollSpy();
       $('.row .table-of-contents').pushpin({ top: 80 });
     }); 
   })(jQuery);
    
  }

}
