<div class="row">
  <div class="col s12 m10 l10">

      <!-- Start of first wrapper -->

      <div class="first-wrapper section scrollspy" id="generate-challenge">
          <p class="first-wrapper-title">OTP Authentication</p>
          <br>
          <!-- Start of Principles Section -->
          <p class="first-wrapper-title">1. Principles</p>
          <p>
            With “Challenge” API, Bizao lets you authenticate the end-user using a PIN-CODE.
The end-user will receive the PIN via SMS. This code has 15mn duration. Two
steps are required to fulfill this authentication:  :
          </p>
          <p class="steps">&#8226;&nbsp; Step 1 : Generate <span class="light-span">challenge</span></p>
          <p><b>Generate Challenge</b> to get a challenge ID based on the end-user’s MSISDN.
              The PIN-CODE is sent at this stage.</p>

          <p class="steps">&#8226;&nbsp; Step 2 : Validate <span class="light-span">challenge</span></p>
          <p> <b>Validate Challenge</b> to generate a <span class="light-span">BIZAO_TOKEN</span> and a <span
                  class="light-span">BIZAO_ALIAS</span> based
              on the challenge ID you received in <b> <i>Step 1</i></b>. <br> The end-user will then be
              authenticated</p>
          <br>

          <p>
            <b>Sample of challenge ID</b> : <span > <em> 5bc5f37f7370537a0fb9f0fc </em></span>
          </p>

          <br>
          <!-- End of Principles section -->

          <!-- Start of Generate challenge section -->
          <p class="first-wrapper-title">2. Generate <i>challenge</i></p>
          <p>To generate Challenge you will use:</p>
          <p>&#8226;&nbsp; <span class="keys">BIZAO-HOST</span> : <code>api.bizao.com</code>
          </p><br>
          <p>&#8226;&nbsp; <span class="keys">BIZAO-URI</span> : <code>challenge/v1/challenges</code></p>
          <br>
          <p>Your query will contain the Headers below :</p>
          <br>
          <table>
              <tr>
                  <td><b>Header</b></td>
                  <td><b>Description/Content</b></td>
              </tr>
              <tr>
                  <td>Content-Type</td>
                  <td>application/json</td>
              </tr>
              <tr>
                <td>Authorization</td>
                <td>YOUR_ACCESS_TOKEN </td>
            </tr>
          </table>
          <br><br><br>
          The body of your query will contain the parameters below into a <b><i>“challenge” object (JSON)</i></b> :
          <br><br>
          <table>
              <tr>
                  <td><b>Parameter</b> </td>
                  <td><b>Usage</b> </td>
                  <td><b>Description</b> </td>
              </tr>
              <tr>
                  <td>method</td>
                  <td>Mandatory</td>
                  <td>Always : <b>OTP-SMS-AUTH</b></td>
              </tr>
              <tr>
                  <td>country</td>
                  <td>Mandatory</td>
                  <td>ISO 3166 alpha 3 country code. <br>
                      You can use the following link to look for country code :
                      <a href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3" target="_blank"><span
                              class="keys">https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3</span></a>
                  </td>
              </tr>
              <tr>
                <td>service</td>
                <td>Mandatory</td>
                <td>Always : <b>: BIZAO </b></td>
            </tr>

            <tr>
              <td>partnerId</td>
              <td>Mandatory</td>
              <td>Always : <b>: PDKSUB </b></td>
          </tr>

          <tr>
            <td>inputs</td>
            <td>Mandatory</td>
            <td> – MSISDN: end-user MSISDN <br>
                – confirmationCode: empty at this stage <br>
                – message: authorized template : &nbsp;<br><br>
               French -> “Pour confirmer votre achat XXXXX merci
                    d’entrer le code : %OTP%” &nbsp;&nbsp;<br><br>
               English -> “Please confirm your purchase XXXXX using this
                    code: %OTP%”<br><br>
                %OTP% is mandatory, &nbsp;
                160 characters maximum.
                <br><br>
                – optLength: always “4” <br>
                – senderName: firendly name displayed on end-user device
                ( size should not exceed 11 characters)
            </td>
        </tr>

          </table>
          <br><br><br>
          <div class="sample-wrapper">
              <span class="light-span">Sample Request :</span> <br><br>
              <pre>
<div class="code">
  curl -i -X POST \
  https://api.bizao.com/challenge/v1/challenges/ \
  -H 'authorization: Bearer YOUR_ACCESS_TOKEN’ \
  -H 'cache-control: no-cache' \
  -H 'content-type: application/json' \
  -d '
  &#123;
  "challenge": &#123;
  "method": "OTP-SMS-AUTH",
  "country": "CIV",
  "service": "BIZAO",
  "partnerId": "PDKSUB",
  "inputs": [&#123;
  "type": "MSISDN",
  "value": "+22500000000"
  &#125;, &#123;
  "type": "confirmationCode",
  "value": ""
  &#125;, &#123;
  "type": "message",
  "value": "Please confirm your purchase XXXXX using this code: %OTP%"
  &#125;, &#123;
  "type": "otpLength",
  "value": "4"
  &#125;, &#123;
  "type": "senderName",
  "value": "NameToDisplay"
  &#125;]
  &#125;
  &#125;'

              </div>
              </pre>

              <br>
              <span class="light-span">Success Response on the header:</span> <br>
              <i>Only the information in the header response will be used to validate the OTP for next step.</i>
              <br><br>
              <div class="code">
                  HTTP/1.1 201 Created <br>
                  <span class="keys">Location</span>:
                  <pre><code>http://[HOST]/challenge/v1/challenges/5bc5f37f7370537a0fb9f0fc</code></pre>
              </div>

          </div>
          <!-- End of Generate challenge -->

      </div>
      <!-- End of first wrapper -->

      <!--Start of second wrapper  -->

      <div class="second-wrapper section scrollspy" id="validate-challenge">
          <!-- Start of Validate Chalenge -->
          <p class="first-wrapper-title">3. Validate challenge</p>
          <p>To validate Challenge you will use :</p>
          <p>&#8226;&nbsp; <span class="keys">BIZAO-URL</span> : <code>/challenge/v1/challenges/challenge_ID</code>
          </p><br>
          <p>&#8226;&nbsp; <span class="keys">BIZAO-HOST</span>: <code>api.bizao.com</code>
          </p><br>




          <br>
          <br>
          <div class="sample-wrapper">
              <span class="light-span">Sample Request :</span>
              <pre>
              <div class="code">
                curl -i -X POST \
                https://api.bizao.com/challenge/v1/challenges/5bc5f37f7370537a0fb9f0fc \
                -H 'authorization: Bearer YOUR_ACCESS_TOKEN' \ -H 'cache-control: nocache' \
                -H 'content-type: application/json' \
                -d '
                &#123;
                "challenge": &#123;
                "method": "OTP-SMS-AUTH",
                "country": "CIV",
                "service": "BIZAO",
                "partnerId": "PDKSUB",
                "inputs": [&#123;
                "type": "MSISDN",
                "value": "+22500000000"
                &#125;,
                &#123;
                "type": "confirmationCode",
                "value": "9683"
                &#125;,
                &#123;
                "type": "info",
                "value": "OrangeApiToken,ise2"
                &#125;
                ]
                &#125;
                &#125; '
</div>
</pre>



<p>You will use the same Header and Body parameters as previously except for the inputs
  parameters :</p>
              <br><br>
              <table>
                <tr>
                    <td><b>Parameter</b></td>
                    <td><b>Usage</b></td>
                    <td><b>Description</b></td>
                </tr>
                <tr>
                    <td>Inputs</td>
                    <td>Mandatory</td>
                    <td>JSON array of &#123;key,value&#125; entries containing all the necessary
                        inputs to perform the challenge: <br>
                        – MSISDN : end-user MSISDN <br>
                        – confirmationCode: the OTP received by the end-user <br>
                        – info: always “OrangeApiToken,ise2” as value
                    </td>
                </tr>
            </table>
              <br><br>
              <span class="light-span"> Success validate challenge response :</span>
              <p><b>Important</b> : <b><i>“OrangeApiToken”</i></b> refers to <span
                      class="light-span">BIZAO_TOKEN</span> in your future calls and <b><i>“ise2” </i></b> to
                  <span class="light-span"> BIZAO_ALIAS</span>. <br> The value of these 2 types may change in the next
                  BIZAO API version
                  and becoming more generic.
              </p>
               <pre>
              <div class="code">
&#123;
"challenge": &#123;
  "method": "OTP-SMS-AUTH",
  "result": [
      &#123;
          "type": "OrangeApiToken",
          "value": "B64n0+sK4nkhwJ1cJh7wrWarjIGDvAm6KPVY4DKnFW3IQZYf82CdCaSso92tFKSFbjy7j+oE88h G+PYcZrece3DxA==|MCO=OSN|tcd=1601376898|ted=16013769
          98|1sALKeLlaU3m6q69a5 /6uBYnxps="
      &#125;,
      &#123;
          "type": "ise2",
          "value": "PDKSUB-200-SSTtmm0TCmd8BOqoxCEqfI/DpkGcHDG3x3ij5e6tpQ="
      &#125;
  ],
  "country": "SEN",
  "service": "BIZAO",
  "partnerId": "PDKSUB",
  "inputs": [
      &#123;
          "type": "MSISDN",
          "value": "+22177xxxxxxx"
      &#125;,
      &#123;
          "type": "confirmationCode",
          "value": "4130"
      &#125;,
      &#123;
          "type": "info",
          "type": "OrangeApiToken,ise2"
      &#125;
  ]
&#125;
&#125;
              </div>
          </pre>

          </div>
      </div>
      <!-- End of second wrapper -->

      <!-- Start of third wrapper -->
      <div class="third-wrapper section scrollspy" id="api-challenge-parameter-by-country">
          <p class="first-wrapper-title">4. Recap of specific API-Challenge parameter value by
              authorized country</p>
          <p>Below a recap of the specific API-Challenge parameters value by authorized country.</p>
          <table>
              <tr>
                  <td><b>Country</b></td>
                  <td><b>Country-Code</b></td>
              </tr>
              <tr>
                  <td>Ivory-Coast</td>
                  <td><i>CIV</i></td>
              </tr>
              <tr>
                  <td>RDCongo</td>
                  <td><i>COD</i></td>
              </tr>
              <tr>
                  <td>Cameroon</td>
                  <td><i>CMR</i></td>
              </tr>
              <tr>
                  <td>Senegal</td>
                  <td><i>SEN</i></td>
              </tr>
              <tr>
                  <td>Tunisia</td>
                  <td><i>TUN</i></td>
              </tr>
              <tr>
                  <td>Burkina Faso</td>
                  <td><i>BFA</i></td>
              </tr>
          </table>
      </div>
      <!-- End of third wrapper -->

      <!-- Start of fourth wrapper -->

      <div class="fourth-wrapper section scrollspy" id="otp-auth-error">
          <div class="first-wrapper-title">5. OTP Authentication error</div>
          <br>
          <p>The following table lists the common error codes end messages that can be generated by
              the Bizao platform:</p>
          <table>
              <tr>
                  <td><b>HTTP code and Status</b> </td>
                  <td><b>Code</b></td>
                  <td><b>Message</b></td>
                  <td><b>Description</b></td>
              </tr>
              <tr>
                  <td>400 Bad request</td>
                  <td>232740000</td>
                  <td>Missing input</td>
                  <td>The following input is
                      missing: [input name]</td>
              </tr>
              <tr>
                  <td>400 Bad request</td>
                  <td>232740001</td>
                  <td>Malformed request body</td>
              </tr>
              <tr>
                  <td>400 Bad request</td>
                  <td>232740002</td>
                  <td>Malformed URI</td>
              </tr>
              <tr>
                  <td>400 Bad request</td>
                  <td>232740003</td>
                  <td>Invalid input value</td>
                  <td>The following input is
                      invalid: [input name]</td>
              </tr>
              <tr>
                  <td>405 Method not
                      supported</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
              </tr>
              <tr>
                  <td>500 Internal Server
                      error</td>
                  <td>232740004</td>
                  <td>Internal Error</td>
                  <td>[Complementary
                      information]</td>
              </tr>
              <tr>
                  <td>404 Not Found</td>
                  <td>232740200</td>
                  <td>Resource not found</td>
                  <td>Unknown challengeId</td>
              </tr>
              <tr>
                  <td>403 Forbidden</td>
                  <td>232740201</td>
                  <td>Authorization denied</td>
              </tr>
              <tr>
                  <td>403 Forbidden</td>
                  <td>232740202</td>
                  <td>Too many retries</td>
              </tr>
              <tr>
                  <td>400 Bad request</td>
                  <td>232740203</td>
                  <td>The provided challenge
                      inputs are invalid</td>
                  <td>[Complementary
                      information]</td>
              </tr>
              <tr>
                  <td>400 Bad request</td>
                  <td>232740203</td>
                  <td>Invalid Content-Type</td>
                  <td>Error on JSON parameters</td>
              </tr>
          </table>
      </div>
      <!-- End of fourth wrapper -->

  </div>

  <div class="col hide-on-small-only s12 m2 l2">
      <ul class="section table-of-contents">
          <li>
              <p class="toc-title">On this page</p>
          </li>
          <li><a href="#generate-challenge">Generate OTP</a></li>
          <li><a href="#validate-challenge">Validate OTP</a></li>
          <li><a href="#otp-auth-error">OTP errors</a></li>
      </ul>
  </div>
</div>
