<div class="container">
    <div class="wrapper">
        <div class="sandbox-warning">
            <img class="responsive-img" src="../../../assets/images/warningSandobx.png" alt="">
        </div>
        <div class="sandbox-header">
            <p class="first-wrapper-title">The Sandbox will be available soon</p>
        </div>
        <div class="sandbox-avatar" >
            <img class="responsive-img"  src="../../../assets/images/sandbox.png" alt="">
        </div>
    </div>
</div>