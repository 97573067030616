import { Component, OnInit} from '@angular/core';
import { environment } from '../environments/environment';
import { FetchDataService } from './services/fetch-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
constructor(private fetchDataService: FetchDataService){}

ngOnInit(){
   //setting ACCESS_TOKEN ahead before any api call
   (() => {
    this.fetchDataService.fetch_access_token().subscribe(
      response => {
       environment.ACCESS_TOKEN=response.access_token;
      }
    );
  })();

}
  title = 'v2DevBizao';
}
