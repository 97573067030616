<div class="row">
  <div class="col s12 m10 l10">
      <div class="first-wrapper">
          <p class="first-wrapper-title section scrollspy" id="principles">Explicite mode (OTP)</p>
          <br>


          <p>
            With “Challenge” API, Bizao lets you authenticate the end-user using a PIN-CODE.
            In this mode you will first collect/get the MSISDN from the user. Then push a subscription
            query to Bizao. Bizao will generate internally an OTP with Chalenge API. Bizao will reply you
            with the status « Subscription Pending » along with the « challenge-id » and « User_id ».

          </p>
<br>

          <p>Bizao will send to endUser the OTP. Once User enters the OTP in his phone, you will validate
            the OTP using validateChallenge API (describe in section 5.).

          </p>


          <p class="first-wrapper-title section scrollspy">OTP-Flow Logic</p>

          <p>To manage the OTP flow we will use two API:
          </p>
          <p style="margin-left: 5%;">
            &#8226;<b> Generate Challenge( Internal API) : </b> Once the SP push a subscription query to Bizao,
            the Hub will generate internally an OTP with the challenge API in order to get the
            challenge ID based on user’s MSISDN.the PIN Code is sent at this stage using MNO
            SMS MT API
            <br>
          </p>
          <p style="margin-left: 5%;">
            &#8226; <b>Validate Challenge (expose to you):</b> to generate a BIZAO_ALIAS(user’s MSISDN)
            based on the challenge ID you received in previous step. The end-user will then be
            authenticated. After authentication, bizao will propagate the subscription query to
            the MNO backend(cf  Validate Challenge).

          </p>


          <p class="first-wrapper-title section scrollspy">OTP Diagram flow</p>

          <p>
            Below the OTP flow diagram including the subscription and notification steps:
          </p>

          <img src="/assets/images/mtnotp.png"  alt=""
          class="ui fluid image">

      </div>
      </div>
      </div>

