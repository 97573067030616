import { Component, OnInit } from '@angular/core';
import { FetchDataService } from 'src/app/services/fetch-data.service';
declare var jQuery: any;
@Component({
  selector: 'app-get-status-visa-mastercard',
  templateUrl: './get-status-visa-mastercard.component.html',
  styleUrls: ['./get-status-visa-mastercard.component.css']
})
export class GetStatusVisaMastercardComponent implements OnInit {
  req_headers:any=null;
  req_body: any = null;
  res_headers:any=null;
  res_body: any = null;
  pulse: boolean = true;
  res_header_active:boolean=false;



  constructor(private fetchDataService: FetchDataService) {

    this.req_headers={
      "Host": "api.bizao.com",
    "Authorization": "Bearer cb422427-1wo6-3be2-b15b-sff651s7bs4e",
    "country-code": "ci"
    }

    this.req_body="There is not Request Body "
  }

  sendRequest() {

this.res_headers="There are no headers in the response"

this.res_body=
  {
    "meta": {
      "type": "payment",
      "source": "visa-mc",
      "category": "education"
      },
      "status": "Successful",
      "amount": "100.00",
      "order-id": "EB_1_bizao_test09_11_2020_04",
      "currency": "XOF",
      "reference": "testref",
      "date": "2020-11-09 14:30:04.0",
      "country-code": "ci",
      "state": "test",
      "intTransaction-id": "f8061034-cc5e-4ba6-b891-c39c64c20f1b",
      "extTransaction-id": "211"
  }

    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {

        console.log(response[0])
        if(response[0].doc.response.success.header.length == 0) {
          this.res_headers= "There are no headers in the response";
          this.res_body = response[0].doc.response.success.body;
        }
        else if(response[0].doc.response.success.body.length == 0) {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = "There is no body in the response";
        }
        else {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = response[0].doc.response.success.body;
        }
      }
    );
    */
    this.res_header_active = true
  }

  togglePulse() {
    this.pulse = false;
  }

  ngOnInit(): void {
    (function ($) {
      $(document).ready(function () {
        $('.tabs').tabs();
      });

      $(document).ready(function () {
        $('.scrollspy').scrollSpy();
      });
    })(jQuery);



  }

  ngAfterViewInit() {
    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {
        console.log(response[0])
        this.req_headers=response[0].doc.request.header;
        this.req_body=response[0].doc.request.body;
      }
    );
    */
  }


}
