import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-mtn-unsubscription-api',
  templateUrl: './mtn-unsubscription-api.component.html',
  styleUrls: ['./mtn-unsubscription-api.component.css']
})
export class MtnUnsubscriptionApiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    (function ($) {
      
      $(document).ready(function(){
       $('.scrollspy').scrollSpy();
       $('.row .table-of-contents').pushpin({ top: 80 });
     }); 
   })(jQuery);
  }

}
