import { Component, OnInit, AfterViewInit} from '@angular/core';
import { environment } from 'src/environments/environment';
import { FetchDataService } from '../../../services/fetch-data.service';
declare var jQuery: any;
@Component({
  selector: 'app-dcb-charge-enduser',
  templateUrl: './dcb-charge-enduser.component.html',
  styleUrls: ['./dcb-charge-enduser.component.css']
})
export class DcbChargeEnduserComponent implements OnInit, AfterViewInit{
  req_headers:any=null ;
  req_body: any = null ;
  res_headers:any=null ;
  res_body: any = null ;
  pulse: boolean = true;
  
  constructor(private fetchDataService: FetchDataService) { }
  sendRequest() {
    this.fetchDataService.process_payment_dcb(environment.ACCESS_TOKEN).subscribe(
      response => {
        if(response[0].doc.response.success.header.length == 0) {
          this.res_headers= "There are no headers in the response";
          this.res_body = response[0].doc.response.success.body;
        }
        else if(response[0].doc.response.success.body.length == 0) {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = "There is no body in the response";
        }
        else {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = response[0].doc.response.success.body;
        }
      }
    );
  }

  togglePulse() {
    this.pulse = false;
  }
  ngOnInit(): void {
    (function ($) {
      $(document).ready(function () {
        $('.tabs').tabs();
      });

      $(document).ready(function () {
        $('.scrollspy').scrollSpy();
      });
    })(jQuery);

    
  }
  ngAfterViewInit() {
    this.fetchDataService.process_payment_dcb(environment.ACCESS_TOKEN).subscribe(
      response => {
        this.req_headers=response[0].doc.request.header;
        this.req_body=response[0].doc.request.body;
      }
    ); 
  }

}
