import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FetchDataService } from 'src/app/services/fetch-data.service';
declare var jQuery: any;

@Component({
  selector: 'app-api-money-transfert',
  templateUrl: './api-money-transfert.component.html',
  styleUrls: ['./api-money-transfert.component.css']
})
export class ApiMoneyTransfertComponent implements OnInit,AfterViewInit {
  req_headers:any=null;
  req_body: any = null;
  res_headers:any=null;
  res_body: any = null;
  pulse: boolean = true;
  res_header_active:boolean=false;



  constructor(private fetchDataService: FetchDataService) {

    this.req_headers={
      "authorization": "cb422427-1wo6-3be2-b15b-sff651s7bs4e",
      "Content-Type": "application/json",
      "country-code": "sn",
      "mno-name" : "free",
      "lang": "fr",
      "channel":"web",
      "type":"wallet"



    }

    this.req_body=
    {
      "senderFirstName":"A sender name",
      "senderLastName":"A sender last name",
      "senderAddress":"A sender address",
      "senderMobileNumber":"33612345678",
      "beneficiaryFirstName":"Ibou",
       "beneficiaryLastName":"Fall",
       "beneficiaryAddress":"Rue 10 angle 20, Medina, Dakar",
       "beneficiaryMobileNumber":"221769876543",
       "currency":"XOF",
       "reference":"MerchantName",
       "order_id":"MerchA_1234598762",
       "amount":"10",
       "state":"param1%3Dvalue1%26param2%3Dvalue2",
    }
  }

  sendRequest() {

this.res_headers="There are no headers in the response"

this.res_body=
  {
    "status": "Successful",
"transaction-Id": "",
"senderFirstName": "A sender name",
"senderLastName": "A sender last name",
"senderAddress": "A sender address",

"senderMobileNumber": "336761234567",
"beneficiaryFirstName": "Ibou",
"beneficiaryLastName": "Fall",
"beneficiaryAddress": "Rue 10 angle 20, Medina, Dakar" ,
"beneficiaryMobileNumber": "221769876543",
"fromCountry": "fr",
"toCountry": "sn",
"currency": "XOF",
"order_id": "MerchA_1234598762",
"amount": 10,
"state": "param1%3Dvalue1%26param2%3Dvalue2"  ,// the Merchant correlation data
"reference": "Merchant-name",
  }

    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {

        console.log(response[0])
        if(response[0].doc.response.success.header.length == 0) {
          this.res_headers= "There are no headers in the response";
          this.res_body = response[0].doc.response.success.body;
        }
        else if(response[0].doc.response.success.body.length == 0) {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = "There is no body in the response";
        }
        else {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = response[0].doc.response.success.body;
        }
      }
    );
    */
    this.res_header_active = true
  }

  togglePulse() {
    this.pulse = false;
  }

  ngOnInit(): void {
    (function ($) {
      $(document).ready(function () {
        $('.tabs').tabs();
      });

      $(document).ready(function () {
        $('.scrollspy').scrollSpy();
      });
    })(jQuery);



  }

  ngAfterViewInit() {
    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {
        console.log(response[0])
        this.req_headers=response[0].doc.request.header;
        this.req_body=response[0].doc.request.body;
      }
    );
    */
  }


}
