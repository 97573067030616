import { NgModule} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DcbChargeEnduserComponent } from './components/api-testor-elements/dcb-charge-enduser/dcb-charge-enduser.component';
import { DcbGenerateOtpComponent } from './components/api-testor-elements/dcb-generate-otp/dcb-generate-otp.component';
import { DcbNotifOrangeComponent } from './components/api-testor-elements/dcb-notif-orange/dcb-notif-orange.component';
import { DcbValidateOtpComponent } from './components/api-testor-elements/dcb-validate-otp/dcb-validate-otp.component';
import { GettingStartedComponent } from './components/api-testor-elements/getting-started/getting-started.component';
import { MobileMoneyApiComponent } from './components/api-testor-elements/mobile-money-api/mobile-money-api.component';
import { MobileMoneyGetstatusComponent } from './components/api-testor-elements/mobile-money-getstatus/mobile-money-getstatus.component';
import { TestBulkApiComponent } from './components/api-testor-elements/test-bulk-api/test-bulk-api.component';
import { TestBulkComponent } from './components/api-testor-elements/test-bulk/test-bulk.component';
import { ApiTestorComponent } from './components/api-testor/api-testor.component';
import { BulkApiComponent } from './components/bulk-api/bulk-api.component';
import { BulkGetStatusApiComponent } from './components/bulk-get-status-api/bulk-get-status-api.component';
import { GetAccessTokenComponent } from './components/get-access-token/get-access-token.component';
import { GetStatusApiComponent } from './components/get-status-api/get-status-api.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { MntOPTAuthenticationComponent } from './components/mnt-optauthentication/mnt-optauthentication.component';
import { MoUserAuthenticationComponent } from './components/mo-user-authentication/mo-user-authentication.component';
import { MobileMoneyPaymentApiComponent } from './components/mobile-money-payment-api/mobile-money-payment-api.component';
import { MoneyTransfertGestatusComponent } from './components/money-transfert-gestatus/money-transfert-gestatus.component';
import { MoneytransferApiComponent } from './components/moneytransfer-api/moneytransfer-api.component';
import { MtnAPIAccessComponent } from './components/mtn-apiaccess/mtn-apiaccess.component';
import { MtnHeAuthenticationComponent } from './components/mtn-he-authentication/mtn-he-authentication.component';
import { MtnSubscriptionAPIComponent } from './components/mtn-subscription-api/mtn-subscription-api.component';
import { MtnSubscriptionNotifApiComponent } from './components/mtn-subscription-notif-api/mtn-subscription-notif-api.component';
import { MtnUnsubscriptionApiComponent } from './components/mtn-unsubscription-api/mtn-unsubscription-api.component';
import { MtnUnsubscriptionNotifApiComponent } from './components/mtn-unsubscription-notif-api/mtn-unsubscription-notif-api.component';
import { MtnValidateChallengeApiComponent } from './components/mtn-validate-challenge-api/mtn-validate-challenge-api.component';
import { OrangeAPIAccessComponent } from './components/orange-apiaccess/orange-apiaccess.component';
import { OrangeDcbNotifApiComponent } from './components/orange-dcb-notif-api/orange-dcb-notif-api.component';
import { OrangeDcbPaymentApiComponent } from './components/orange-dcb-payment-api/orange-dcb-payment-api.component';
import { OrangeHeAuthComponent } from './components/orange-he-auth/orange-he-auth.component';
import { OrangeUnsubscriptionApiComponent } from './components/orange-unsubscription-api/orange-unsubscription-api.component';
import { OrangeUssdStopSubscriptionComponent } from './components/orange-ussd-stop-subscription/orange-ussd-stop-subscription.component';
import { OrangeUssdStoreComponent } from './components/orange-ussd-store/orange-ussd-store.component';
import { OtpAuthenticationComponent } from './components/otp-authentication/otp-authentication.component';
import { SandboxComponent } from './components/sandbox/sandbox.component';
import { VisaMastercardGetStatusComponent } from './components/visa-mastercard-get-status/visa-mastercard-get-status.component';
import { VisaMastercardComponent } from './components/visa-mastercard/visa-mastercard.component';


const routes: Routes = [
  {path:'' , redirectTo: 'home', pathMatch: 'full'},
  {path:'genOTP' , redirectTo: 'api-testor', pathMatch: 'full'},
  {path:'valOTP' , redirectTo: 'api-testor', pathMatch: 'full'},
  {path:'payOTP' , redirectTo: 'api-testor', pathMatch: 'full'},
  {path:'home', component:HomePageComponent},
  {path:'get_access_token', component:GetAccessTokenComponent},
  {path:'otp_authentication', component:OtpAuthenticationComponent},
  {path:'orange-he-auth', component:OrangeHeAuthComponent},
  {path:'mo-user-authentication', component:MoUserAuthenticationComponent},
  {path:'orange-dcb-payment-api', component:OrangeDcbPaymentApiComponent},
  {path:'orange-dcb-notification-api', component:OrangeDcbNotifApiComponent},
  {path:'orange-unsubscription-api', component:OrangeUnsubscriptionApiComponent},
  {path:'mtn-he-authentication', component:MtnHeAuthenticationComponent},
  {path:'mtn-unsubscription-api', component:MtnUnsubscriptionApiComponent},
  {path:'mtn-subscription-notif-api', component:MtnSubscriptionNotifApiComponent},
  {path:'mtn-unsubscription-notif-api', component:MtnUnsubscriptionNotifApiComponent},
  {path:'mobile-money-payment-api', component:MobileMoneyPaymentApiComponent},
  {path:'get-status-api', component:GetStatusApiComponent},
  {path:'sandbox', component:SandboxComponent},
  {path:'api-testor', component:ApiTestorComponent},
  {path:'api-testor-getting-started', component:GettingStartedComponent},
  {path:'api-testor-dcb-generate-otp', component:DcbGenerateOtpComponent},
  {path:'api-testor-dcb-validate-otp', component:DcbValidateOtpComponent},
  {path:'api-testor-dcb-make-payment', component:DcbChargeEnduserComponent},
  {path:'api-testor-dcb-notif', component:DcbNotifOrangeComponent},
  {path:'api-testor-mobile-money-api', component:MobileMoneyApiComponent},
  {path:'api-testor-get-status-api', component:MobileMoneyGetstatusComponent},
  {path:'bulk-api', component:BulkApiComponent},
  {path:'bulk-get-status-api', component:BulkGetStatusApiComponent},
  {path:'moneytransfer-api', component:MoneytransferApiComponent},
  {path:'moneytransfer-getstatusapi', component:MoneyTransfertGestatusComponent},
  {path:'test-bulk-api', component:TestBulkApiComponent},
  {path:'test-bulk', component:TestBulkComponent},
  {path:'visa-mastercard', component:VisaMastercardComponent},
  {path: 'visa-mastercard-get-status',component:VisaMastercardGetStatusComponent},
  {path:'orange-api-access', component:OrangeAPIAccessComponent},
  {path:'mtn-api-access', component:MtnAPIAccessComponent},
  {path:'mtn-otp-authentication', component:MntOPTAuthenticationComponent},
  {path:'mtn-validate-challenge-api', component:MtnValidateChallengeApiComponent},
  {path:'orange-ussd-stop-unsubsciption', component:OrangeUssdStopSubscriptionComponent},
  {path:'orange-ussd-store', component:OrangeUssdStoreComponent},
  {path:'mtn-subscription-api', component:MtnSubscriptionAPIComponent}


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
