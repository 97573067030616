import { Component, OnInit } from '@angular/core';
import {FetchDataService} from '../../services/fetch-data.service';
declare var jQuery: any;
@Component({
  selector: 'app-orange-dcb-payment-api',
  templateUrl: './orange-dcb-payment-api.component.html',
  styleUrls: ['./orange-dcb-payment-api.component.css']
})
export class OrangeDcbPaymentApiComponent implements OnInit {

  constructor(private fetchDataService : FetchDataService) { }


  switchToPayOTP(){
    this.fetchDataService.tab='dcb';
    this.fetchDataService.dcb_key = 'pay';
  }


  ngOnInit(): void {
    (function ($) {
      
      $(document).ready(function(){
       $('.scrollspy').scrollSpy();
       $('.row .table-of-contents').pushpin({ top: 80 });
     }); 
   })(jQuery);
   
  }

}
