import { HttpClientModule } from '@angular/common/http';
import {PrettyJsonModule} from 'angular2-prettyjson';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CommonModule, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { NgModule } from '@angular/core';
import {SuiModule} from 'ng2-semantic-ui';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeftSideNavigationComponent } from './components/left-side-navigation/left-side-navigation.component';
import { MainNavigationComponent } from './components/main-navigation/main-navigation.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContentContainerComponent } from './components/content-container/content-container.component';
import { MainWrapperComponent } from './components/main-wrapper/main-wrapper.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { GetAccessTokenComponent } from './components/get-access-token/get-access-token.component';
import { OtpAuthenticationComponent } from './components/otp-authentication/otp-authentication.component';
import { OrangeHeAuthComponent } from './components/orange-he-auth/orange-he-auth.component';
import { MoUserAuthenticationComponent } from './components/mo-user-authentication/mo-user-authentication.component';
import { OrangeDcbPaymentApiComponent } from './components/orange-dcb-payment-api/orange-dcb-payment-api.component';
import { OrangeDcbNotifApiComponent } from './components/orange-dcb-notif-api/orange-dcb-notif-api.component';
import { OrangeUnsubscriptionApiComponent } from './components/orange-unsubscription-api/orange-unsubscription-api.component';
import { MtnHeAuthenticationComponent } from './components/mtn-he-authentication/mtn-he-authentication.component';
import { MtnUnsubscriptionApiComponent } from './components/mtn-unsubscription-api/mtn-unsubscription-api.component';
import { MtnSubscriptionNotifApiComponent } from './components/mtn-subscription-notif-api/mtn-subscription-notif-api.component';
import { MtnUnsubscriptionNotifApiComponent } from './components/mtn-unsubscription-notif-api/mtn-unsubscription-notif-api.component';
import { MobileMoneyPaymentApiComponent } from './components/mobile-money-payment-api/mobile-money-payment-api.component';
import { GetStatusApiComponent } from './components/get-status-api/get-status-api.component';
import { SandboxComponent } from './components/sandbox/sandbox.component';
import { ApiTestorComponent } from './components/api-testor/api-testor.component';
import { GettingStartedComponent } from './components/api-testor-elements/getting-started/getting-started.component';
import { DcbComponent } from './components/api-testor-elements/dcb/dcb.component';
import { MobileMoneyComponent } from './components/api-testor-elements/mobile-money/mobile-money.component';
import { DcbGenerateOtpComponent } from './components/api-testor-elements/dcb-generate-otp/dcb-generate-otp.component';
import { DcbValidateOtpComponent } from './components/api-testor-elements/dcb-validate-otp/dcb-validate-otp.component';
import { DcbChargeEnduserComponent } from './components/api-testor-elements/dcb-charge-enduser/dcb-charge-enduser.component';
import { MobileMoneyApiComponent } from './components/api-testor-elements/mobile-money-api/mobile-money-api.component';
import { MobileMoneyGetstatusComponent } from './components/api-testor-elements/mobile-money-getstatus/mobile-money-getstatus.component';
import { DcbNotifOrangeComponent } from './components/api-testor-elements/dcb-notif-orange/dcb-notif-orange.component';
import { BulkApiComponent } from './components/bulk-api/bulk-api.component';
import { BulkGetStatusApiComponent } from './components/bulk-get-status-api/bulk-get-status-api.component';
import { MoneytransferApiComponent } from './components/moneytransfer-api/moneytransfer-api.component';
import { TestBulkApiComponent } from './components/api-testor-elements/test-bulk-api/test-bulk-api.component';
import { TestBulkComponent } from './components/api-testor-elements/test-bulk/test-bulk.component';
import { BulkqueryApiComponent } from './components/api-testor-elements/bulkquery-api/bulkquery-api.component';
import { GetBalanceApiComponent } from './components/api-testor-elements/get-balance-api/get-balance-api.component';
import { VisaMastercardComponent } from './components/visa-mastercard/visa-mastercard.component';
import { VisaMastercardApiComponent } from './components/api-testor-elements/visa-mastercard-api/visa-mastercard-api.component';
import { MoneyTransferApiComponent } from './components/api-testor-elements/money-transfer-api/money-transfer-api.component';
import { MobileMoneyPaymentAPIComponent } from './components/api-testor-elements/mobile-money-payment-api/mobile-money-payment-api.component';
import { MobileMoneyGetStatusAPIComponent } from './components/api-testor-elements/mobile-money-get-status-api/mobile-money-get-status-api.component';
import { DebitCardAPIComponent } from './components/api-testor-elements/debit-card-api/debit-card-api.component';
import { GetStatusVisaMastercardComponent } from './components/api-testor-elements/get-status-visa-mastercard/get-status-visa-mastercard.component';
import { ApiMoneyTransfertComponent } from './components/api-testor-elements/api-money-transfert/api-money-transfert.component';
import { BulkPaymentApiComponent } from './components/api-testor-elements/bulk-payment-api/bulk-payment-api.component';
import { GetStatusBulkComponent } from './components/api-testor-elements/get-status-bulk/get-status-bulk.component';
import { GetBalanceBulkComponent } from './components/api-testor-elements/get-balance-bulk/get-balance-bulk.component';
import { MoneyTransfertGestatusComponent } from './components/money-transfert-gestatus/money-transfert-gestatus.component';
import { MoneyTransferGetStatusAPIComponent } from './components/api-testor-elements/money-transfer-get-status-api/money-transfer-get-status-api.component';
import { VisaMastercardGetStatusComponent } from './components/visa-mastercard-get-status/visa-mastercard-get-status.component';
import { OrangeAPIAccessComponent } from './components/orange-apiaccess/orange-apiaccess.component';
import { MtnAPIAccessComponent } from './components/mtn-apiaccess/mtn-apiaccess.component';
import { MntOPTAuthenticationComponent } from './components/mnt-optauthentication/mnt-optauthentication.component';
import { MtnSubscriptionAPIComponent } from './components/mtn-subscription-api/mtn-subscription-api.component';
import { MtnValidateChallengeApiComponent } from './components/mtn-validate-challenge-api/mtn-validate-challenge-api.component';
import { OrangeUssdStopSubscriptionComponent } from './components/orange-ussd-stop-subscription/orange-ussd-stop-subscription.component';
import { OrangeUssdStoreComponent } from './components/orange-ussd-store/orange-ussd-store.component';







@NgModule({
  declarations: [
    AppComponent,
    LeftSideNavigationComponent,
    MainNavigationComponent,
    FooterComponent,
    ContentContainerComponent,
    MainWrapperComponent,
    HomePageComponent,
    GetAccessTokenComponent,
    OtpAuthenticationComponent,
    OrangeHeAuthComponent,
    MoUserAuthenticationComponent,
    OrangeDcbPaymentApiComponent,
    OrangeDcbNotifApiComponent,
    OrangeUnsubscriptionApiComponent,
    MtnHeAuthenticationComponent,
    MtnUnsubscriptionApiComponent,
    MtnSubscriptionNotifApiComponent,
    MtnUnsubscriptionNotifApiComponent,
    MobileMoneyPaymentApiComponent,
    GetStatusApiComponent,
    SandboxComponent,
    ApiTestorComponent,
    GettingStartedComponent,
    DcbComponent,
    MobileMoneyComponent,
    DcbGenerateOtpComponent,
    DcbValidateOtpComponent,
    DcbChargeEnduserComponent,
    MobileMoneyApiComponent,
    MobileMoneyGetstatusComponent,
    DcbNotifOrangeComponent,
    BulkApiComponent,
    BulkGetStatusApiComponent,
    MoneytransferApiComponent,
    TestBulkApiComponent,
    TestBulkComponent,
    BulkqueryApiComponent,
    GetBalanceApiComponent,
    VisaMastercardComponent,
    VisaMastercardApiComponent,
    MoneyTransferApiComponent,
    MobileMoneyPaymentAPIComponent,
    MobileMoneyGetStatusAPIComponent,
    DebitCardAPIComponent,
    GetStatusVisaMastercardComponent,
    ApiMoneyTransfertComponent,
    BulkPaymentApiComponent,
    GetStatusBulkComponent,
    GetBalanceBulkComponent,
    MoneyTransfertGestatusComponent,
    MoneyTransferGetStatusAPIComponent,
    VisaMastercardGetStatusComponent,
    OrangeAPIAccessComponent,
    MtnAPIAccessComponent,
    MntOPTAuthenticationComponent,
    MtnSubscriptionAPIComponent,
    MtnValidateChallengeApiComponent,
    OrangeUssdStopSubscriptionComponent,
    OrangeUssdStoreComponent



  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SuiModule,
   BrowserAnimationsModule,
   CommonModule,
   HttpClientModule,
   PrettyJsonModule

  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
