<div class="row">
    <div class="col s12 m6 l6 ">
        <p class="section-subtitle ">Steps</p>
        <hr style="border-color: #E3E8EE; border-style: solid;">
        <ul class="bar">
            <li><a routerLink="#" [ngClass]="{'is-active' : key === 'genOTP'}" (click)="changeKeyToGenOTP()">Generate
                    OTP</a></li>
            <li><a routerLink="#" [ngClass]="{'is-active' : key === 'valOTP'}" (click)="changeKeyToValOTP()">Validate
                    OTP</a></li>
            <li><a routerLink="#" [ngClass]="{'is-active' : key === 'pay'}" (click)="changeKeyToPay()">Process
                    Payment</a></li>
            <li><a routerLink="#" [ngClass]="{'is-active' : key === 'notif'}" (click)="changeKeyToNotif()">Get Notifications</a></li>
        </ul>
    </div>
    <div class="col s12 m6 l6 ">
        <p class="section-subtitle">Description</p>
        <hr style="border-color: #E3E8EE; border-style: solid;">
        <p *ngIf="key === 'genOTP' ">First, generate an OTP to be sent to the end-user. </p>
        <p *ngIf="key === 'valOTP' ">Then, you need to check the validaty of the OTP entered by the end-user. </p>
        <p *ngIf="key ===  'pay' "> Then, use the BIZAO API Payment to charge the end-user.</p>
        <p *ngIf="key ===  'notif' "> Finally, use the BIZAO API Notification to get information about the transaction status.</p>
    </div>
</div>
<hr style="border-color: #E3E8EE; border-style: solid;">

<div *ngIf=" key === 'genOTP' ">
  <app-dcb-generate-otp></app-dcb-generate-otp>
</div>
<div *ngIf=" key === 'valOTP' ">
    <app-dcb-validate-otp></app-dcb-validate-otp>
  </div>
  <div *ngIf=" key === 'pay' ">
    <app-dcb-charge-enduser></app-dcb-charge-enduser>
  </div>
  <div *ngIf=" key === 'notif' ">
    <app-dcb-notif-orange></app-dcb-notif-orange>
  </div>