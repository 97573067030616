<div class="row">
    <div class="col s12 m10 l10">

        <!-- Start of first wrapper -->
        <div class="first-wrapper">
            <p class="first-wrapper-title">Generate your ACCESS_TOKEN</p>
            <p>After subscription, the Bizao Team will give you an <span class="light-span">ACCESS_TOKEN</span> to use as Header in all
                calls you will make to our APIs:</p>
                <p>
                    Sample of an <span class="light-span">ACCESS_TOKEN</span> :
                </p>
           <div class="section scrollspy" id="main-credentials">
            <p class="first-wrapper-title">1. Main credentials</p>
            <p> At subscription the Bizao team will provide you with : </p>
            <p>&#8226;&nbsp; a <span class="light-span">CLIENT_ID</span> : Sample <span
                    class="code"><code>"N2xe7xzk25FZEnS0YgmlD10a"</code></span></p>
            <p> &#8226;&nbsp; a <span class="light-span">CLIENT_SECRET</span> : Sample <span
                    class="code"><code>"xpPDskBI9GKpbPjkHJrAN6Qa"</code></span></p>
           </div>
            <br>

          <div class="section scrollspy" id="generate-access-token">
            <p class="first-wrapper-title">2. Generate your ACCESS_TOKEN</p>
            <p>Here are the steps to generate your ACCESS_TOKEN :</p>
            <p class="steps">&#8226;&nbsp; Step 1 : Generate YOUR BASIC_TOKEN</p>
            <p>Generate a <span class="light-span">BASIC_TOKEN</span> based on your <span class="light-span">CLIENT_ID</span> and your <span
                    class="light-span">CLIENT_SECRET</span>
                encoded in base64. See the sample below:</p>
            <pre><p><span class="light-span">CLIENT_ID</span> : <span class="code"><code>"N2xe7xzk25FZEnS0YgmlD10a"</code></span></p></pre>
            <pre><p><span class="light-span">CLIENT_SECRET</span> : <span class="code"><code>"xpPDskBI9GKpbPjkHJrAN6Qa"</code></span></p></pre>
                <pre><span class="light-span">YOUR BASIC TOKEN</span> :<code class="code">"TjJ4ZTd4emsyNUZaRW5TMFlnbWxEMTBhOnhwUERza0JJOUdLcGJQamtISnJBTjZRY Q=="</code></pre><br>
                <p>You can visit this <a href="https://www.base64encode.org/" target="_blank" class="encodingWebsite">website</a> to generate your <span class="light-span">BASIC_TOKEN</span> by encoding your <span class="light-span">CLIENT_ID</span>
                and <span class="light-span">CLIENT_SECRET</span> under the following format : <b>CLIENT_ID:CLIENT_SECRET</b></p>
                <div class="sample-wrapper">
                  <img src="../../../assets/images/basicToken.png" alt="" class="responsive-img">
                </div> <br><br>
            <p>Below a code sample on how to generate your <span class="light-span">BASIC_TOKEN</span> using PHP : </p>
            <div class="sample-wrapper">
                <div class="code">
                    <code>
                        &lsaquo;?php <br>
                        <span class="keys">$id</span> = "N2xe7xzk25FZEnS0YgmlD10a"; <br>
                        <span class="keys">$secret</span> = "xpPDskBI9GKpbPjkHJrAN6Qa"; <br>
                        <span class="keys">$yourBasicAuthorization</span> = base64_encode("$id:$secret"); <br>
                          echo <span class="keys">$yourBasicAuthorization</span>;<br>
                          ?&rsaquo;
                    </code>
                </div>
            </div> <br>
            <br><br>
            <p class="steps">&#8226;&nbsp; Step 2 :</p>
            <p>Make a request to the endpoint <span class="code"><code class="keys">https://preproduction-gateway.bizao.com/token</code></span> by providing your generated <span class="light-span">BASIC_TOKEN</span> <br>
               ( here <b>TjJ4ZTd4emsyNUZaRW5TMFlnbWxEMTBhOnhwUERza0JJOUdLcGJQamtISnJBTjZRYQ==</b> ).</p> <br>
            <div class="sample-wrapper">
                <span class="light-span">Request sample :</span> <br><br>
            <div class="code">
                <code>
 <pre>
curl --location --request POST 'https://api.bizao.com/token' \
-H 'authorization : Basic TjJ4ZTd4emsyNUZaRW5TMFlnbWxEMTBhOnhwUERza0JJOUdLcGJQamtISnJBTjZRYQ==' \
-H 'content-type : application/x-www-form-urlencoded' \
-d 'grant_type=client_credentials' \
 </pre>
                </code>
            </div>
            <br><br>
            <span class="light-span">Success query response :</span> <br><br>
            <div class="code">
                <code>
<pre>
 &#123;
    "access_token": "4xx1bae4-3f9b-346-9w1b-c0e4d4ef",
    "scope": "am_application_scope default",
    "token_type": "Bearer",
    "expires_in": 31536000
 &#125;
</pre>
                </code>
            </div>
            <div class="tryitbutton">
                <a routerLink="/api-testor" (click)="switchToGettingStarted()" class="btn">Try it here</a>
            </div>
            </div>
            <br>
            <p>As long as your <span class="light-span">ACCESS_TOKEN </span> &nbsp; is valid, this request will give you the same token with the
                remaining time in seconds before expiration (see expires_in parameter).</p>
                <p>After the expiration date, this request will regenerate a new <span class="light-span">ACCESS_TOKEN</span>.</p>
          </div>

        </div>
        <!-- End of first wrapper -->
        <!-- Start of second wrapper -->
        <!-- End of secodn wrapper -->
    </div>

    <!-- Start of table of contents -->

    <div class="col hide-on-small-only m2 l2">
        <ul class="section table-of-contents toc">
            <li><p class="toc-title">On this page</p></li>
            <!-- <li class="toc-title"><a href="#">On this Page</a></li> -->
            <li><a href="#main-credentials">Main Credentials</a></li>
            <li><a href="#generate-access-token">Access Token</a></li>
        </ul>
    </div>

    <!-- End of table of contents -->

</div>


