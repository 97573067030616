<div class="hide-on-med-and-down">
    <ul class="left_nav_wrapper" id="ul_left_nav">
        <li><a href="#" class="main-title"><img src="../../../assets/images/gettingStarted.svg" alt=""
                    class="align-position">Getting Started</a></li>
        <li><a routerLink="/get_access_token" routerLinkActive="is-active" class="secondary-titles token">Generate Access Token</a></li>
        <li><a routerLink="/sandbox" routerLinkActive="is-active" class="secondary-titles">Create Sandbox Account</a></li>
        <br>
        <li class="main-title-margin"><a href="#" class="main-title"><img src="../../../assets/images/ourServices.svg"
                    alt="" class="align-position">Our offers</a></li>
        <li>
            <a class=" modal-trigger secondary-titles"  href="#dcb-digest">Direct Carrier Billing</a>

        </li>
        <li>
            <a class="modal-trigger secondary-titles" href="#mm-digest">
                Merchant Payment</a>
        </li>
        <li>
            <a class="modal-trigger secondary-titles" href="#mt-digest">Money transfer</a>
        </li>
        <br>
        <li class="main-title-margin api_bookstore"><a href="#" class="main-title"><img src="../../../assets/images/apiBookstore.svg"
                    alt="" class="align-position">APIs Bookstore</a></li>

        <li>
            <sui-accordion [closeOthers]="false">
                <sui-accordion-panel [isOpen]="false">
                    <div title class="bookstore_title ">
                        <i class="dropdown icon"></i>
                        <i class="code icon"></i>
                        Direct Carrier Billing
                    </div>
                    <div content>
                        <!-- Start of Accordion Panel for Orange DCB -->
                        <sui-accordion-panel [isOpen]="false">
                            <div title class="orange-title bookstore_title">
                                <i class="dropdown icon"></i>
                                Orange
                            </div>
                            <div content>
                                <!-- Start of Subscription accordion orange DCB-->
                           <!--     <sui-accordion-panel [isOpen]="false">
                                    <div title class="subtitle">
                                        <i class="dropdown icon"></i>
                                        Subscription
                                    </div>
                                    <div content>-->
                                      <sui-accordion-panel [isOpen]="false">
                                        <div title class="subtitle">
                                            <i class="dropdown icon"></i>
                                            API Access
                                        </div>
                                        <div content>
                                            <div class="item-wrapper">
                                                <div class="item">
                                                    <a class="item-submenu" routerLink="/orange-api-access" routerLinkActive="is-active">BIZAO API Access</a>
                                                </div>
                                            </div>
                                        </div>

                                    </sui-accordion-panel>
                                        <sui-accordion-panel [isOpen]="false">
                                            <div title class="subtitle">
                                                <i class="dropdown icon"></i>
                                                Authentication
                                            </div>
                                            <div content>
                                                <div class="item-wrapper">
                                                    <div class="item">
                                                        <a class="item-submenu" routerLink="/otp_authentication" routerLinkActive="is-active">OTP</a>
                                                    </div>
                                                    <div class="item">
                                                        <a  class="item-submenu" routerLink="/orange-he-auth" routerLinkActive="is-active">3G/4G</a>
                                                    </div>
                                                    <div class="item">
                                                        <a class="item-submenu" routerLink="/mo-user-authentication" routerLinkActive="is-active">MO</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </sui-accordion-panel>

                                        <!-- End  of Subscription accordion orange DCB -->

                                        <!-- Start of Payment API accordion orange DCB -->

                                        <sui-accordion-panel [isOpen]="false">
                                            <div title class="subtitle">
                                                <i class="dropdown icon"></i>
                                                Payment
                                            </div>
                                            <div content>
                                                <div class="item-wrapper">
                                                    <div class="item">
                                                        <a class="item-submenu" routerLink="/orange-dcb-payment-api" routerLinkActive="is-active">Payment API</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </sui-accordion-panel>
                                        <!-- End of Payment API accordion orange DCB -->


                                        <!-- Start of DCB notification API  -->
                                        <sui-accordion-panel [isOpen]="false">
                                            <div title class="subtitle">
                                                <i class="dropdown icon"></i>
                                                Notification
                                            </div>
                                            <div content>
                                                <div class="item-wrapper">
                                                    <div class="item">
                                                        <a class="item-submenu" routerLink="/orange-dcb-notification-api" routerLinkActive="is-active">SMS MT
                                                            API</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </sui-accordion-panel>
                                        <!-- End of DCB notification API -->
                                 <!--     </div>
                              </sui-accordion-panel> -->
                                <!-- End of Subscription accordion orange DCB-->

                                <!-- Start of Unsubscription accordion orange DCB-->
                                <sui-accordion-panel>
                                    <div title class="subtitle bookstore_title">
                                        <i class="dropdown icon"></i>
                                        Unsubscription
                                    </div>
                                    <div content>
                                        <div class="item-wrapper-unsub">
                                            <div class="item">
                                                <a class="item-submenu" routerLink="/orange-unsubscription-api" routerLinkActive="is-active">Unsubscription
                                                    API</a>
                                                </div>
                                                <div class="item">
                                                  <a class="item-submenu" routerLink="/orange-ussd-store" routerLinkActive="is-active">USSD Store</a>
                                              </div>
                                        </div>
                                    </div>
                                </sui-accordion-panel>
                                <!-- End of Unsubscription accordion orange DCB-->
                            </div>
                        </sui-accordion-panel>


                        <!-- End of Accordion Panel for Orange DCB -->



                        <!-- Start of Accordion Panel for MTN -->

                        <sui-accordion-panel [isOpen]="false">
                            <div title class="mtn-title bookstore_title">
                                <i class="dropdown icon"></i>
                                MTN
                            </div>
                            <div content>

                              <sui-accordion-panel [isOpen]="false">
                                <div title class="subtitle">
                                    <i class="dropdown icon"></i>
                                    API Access
                                </div>
                                <div content>
                                    <div class="item-wrapper">
                                        <div class="item">
                                            <a class="item-submenu" routerLink="/mtn-api-access" routerLinkActive="is-active">BIZAO API Access</a>
                                        </div>
                                    </div>
                                </div>

                            </sui-accordion-panel>
                                <sui-accordion-panel [isOpen]="false">
                                    <div title class="subtitle bookstore_title">
                                        <i class="dropdown icon"></i>
                                        Subscription
                                    </div>
                                    <div content>

                                      <sui-accordion-panel [isOpen]="false">
                                        <div title class="subsubtitle">
                                            <i class="dropdown icon"></i>
                                            Authentication
                                        </div>
                                        <div content>
                                            <div class="item-wrapper">
                                                <div class="item">
                                                    <a class="item-submenu" routerLink="/mtn-he-authentication" routerLinkActive="is-active">Implicit mode
                                                        &#x28;HE&#x29;</a>
                                                </div>
                                                <div class="item">
                                                  <a class="item-submenu" routerLink="/mtn-otp-authentication" routerLinkActive="is-active">Explicite mode (OTP)
                                                    </a>
                                              </div>

                                            </div>
                                        </div>
                                    </sui-accordion-panel>

                                      <sui-accordion-panel>
                                        <div title class="subsubtitle">
                                            <i class="dropdown icon"></i>
                                            Subscription API
                                        </div>
                                        <div content>
                                            <div class="item-wrapper">
                                              <div class="item">
                                                <a class="item-submenu" routerLink="/mtn-subscription-api" routerLinkActive="is-active">Subscription API
                                                  </a>
                                            </div>
                                            </div>
                                        </div>
                                    </sui-accordion-panel>

                                    <sui-accordion-panel>
                                      <div title class="subsubtitle">
                                          <i class="dropdown icon"></i>
                                          Validation Challenge API
                                      </div>
                                      <div content>
                                          <div class="item-wrapper">
                                            <div class="item">
                                              <a class="item-submenu" routerLink="/mtn-validate-challenge-api" routerLinkActive="is-active">Validate Challenge API
                                                </a>
                                          </div>
                                          </div>
                                      </div>
                                  </sui-accordion-panel>


                                        <sui-accordion-panel>
                                            <div title class="subsubtitle bookstore_title">
                                                <i class="dropdown icon"></i>
                                                Notification
                                            </div>
                                            <div content>
                                                <div class="item-wrapper">
                                                    <div class="item">
                                                        <a class="item-submenu" routerLink="/mtn-subscription-notif-api" routerLinkActive="is-active">Notification API</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </sui-accordion-panel>
                                    </div>
                                </sui-accordion-panel>
                                <sui-accordion-panel>
                                    <div title class="subtitle bookstore_title">
                                        <i class="dropdown icon"></i>
                                        Unsubscription
                                    </div>
                                    <div content>
                                        <sui-accordion-panel>
                                            <div title class="subsubtitle bookstore_title">
                                                <i class="dropdown icon"></i>
                                                Unsubscription API
                                            </div>
                                            <div content>
                                                <div class="item-wrapper">
                                                    <div class="item">
                                                        <a class="item-submenu" routerLink="/mtn-unsubscription-api" routerLinkActive="is-active">Unsubscription API</a>
                                                    </div>


                                                </div>
                                            </div>
                                        </sui-accordion-panel>
                                        <sui-accordion-panel>
                                            <div title class="subsubtitle bookstore_title">
                                                <i class="dropdown icon"></i>
                                                Notification
                                            </div>
                                            <div content>
                                                <div class="item-wrapper">
                                                    <div class="item">
                                                        <a class="item-submenu" routerLink="/mtn-unsubscription-notif-api" routerLinkActive="is-active">Notification
                                                            API</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </sui-accordion-panel>
                                    </div>
                                </sui-accordion-panel>
                            </div>
                        </sui-accordion-panel>

                        <!-- End of Accordion Panel for MTN -->
                    </div>
                </sui-accordion-panel>

            </sui-accordion>
        </li>
        <li>
            <sui-accordion [closeOthers]="false">
                <sui-accordion-panel [isOpen]="false">
                    <div title>
                        <i class="dropdown icon"></i>
                        <i class="code icon"></i>
                        Mobile Money
                    </div>
                    <div content>
                        <div class="mobile-money-item-wrapper">
                            <div class="item">
                                <a routerLink="/mobile-money-payment-api" routerLinkActive="is-active">Payment API</a>
                            </div>
                            <div class="item">
                                <a routerLink="/get-status-api" routerLinkActive="is-active">GetStatus API</a>
                            </div>
                        </div>
                    </div>
                </sui-accordion-panel>
            </sui-accordion>
        </li>
        <li>
        <sui-accordion [closeOthers]="false">
          <sui-accordion-panel [isOpen]="false">
              <div title>
                  <i class="dropdown icon"></i>
                  <i class="code icon"></i>
                  MoneyTransfer
              </div>
              <div content>
                  <div class="mobile-money-item-wrapper">
                      <div class="item">
                          <a class="item-submenu" routerLink="/moneytransfer-api" routerLinkActive="is-active">MoneyTransfer API</a>
                      </div>
                      <div class="item">
                        <a class="item-submenu" routerLink="/moneytransfer-getstatusapi" routerLinkActive="is-active">GetStatus API</a>
                    </div>
                  </div>
              </div>
          </sui-accordion-panel>
      </sui-accordion>
    </li>
        <li>
          <sui-accordion [closeOthers]="false">
              <sui-accordion-panel [isOpen]="false">
                  <div title>
                      <i class="dropdown icon"></i>
                      <i class="code icon"></i>
                      Bulk Payment
                  </div>
                  <div content>
                      <div class="mobile-money-item-wrapper">
                          <div class="item">
                              <a class="item-submenu" routerLink="/bulk-api" routerLinkActive="is-active">Bulk API</a>
                          </div>
                          <div class="item">
                              <a class="item-submenu" routerLink="/bulk-get-status-api" routerLinkActive="is-active">GetStatus API</a>
                          </div>
                      </div>
                  </div>
              </sui-accordion-panel>
          </sui-accordion>

      </li>


    </ul>

</div>

<!-- Modal Structure for dcb-->
<div id="dcb-digest" class="modal">
    <div class="modal-content">
        <h4>Direct Carrier Billing</h4>
        <p>
            Monetizing digital content in Africa can seem like a puzzle because of the very high use of cash in daily
            transactions and the low use of credit cards for online payments. Africa’s mobile penetration rate of over
            80% offers new and unique opportunities for digital content publishers. Bizao’s technical platform allows
            content publishers to access the billing systems of telecom operators in order to bill their services
            directly on the telephone credit. Thus, your digital contents (music, SVOD, games, newspapers, …) are
            accessible to millions of users in several African countries in one click because Bizao has partnerships
            with major telecom operators.
        </p>
    </div>
    <div class="modal-footer learn-more">
        <a href="https://bizao.com/en/carrier-billing-2/" target="_blank" class="modal-close"><u>Learn more about DCB</u></a>
    </div>
</div>

<!-- Modal Structure for mobile money-->
<div id="mm-digest" class="modal">
    <div class="modal-content">
        <h4>Merchant Payment</h4>
        <p>If you are looking for a robust and secure solution allowing your customers or users to pay by mobile money
            or credit card, Bizao is the ideal partner for you. Our solutions are used by multinationals, SMEs,
            associations and public administration. Thanks to our unique contract, you have access to all the payment
            methods of our banking partners (local and international bank cards) and telecom operators (Orange, MTN,
            Free, …) present in about ten countries in Africa. Whether you have a website or not, a mobile application
            or not, we have an answer adapted to your needs for collecting and transferring funds whatever your digital
            maturity.</p>
    </div>
    <div class="modal-footer learn-more">
        <a href="https://bizao.com/en/merchant-payment/" target="_blank" class="modal-close"><u>Learn more about Merchant
            Payment</u></a>
    </div>
</div>


<!-- Modal Structure for money transfer-->
<div id="mt-digest" class="modal">
    <div class="modal-content">
        <h4>Money transfer</h4>
        <p>
            Bizao revolutionizes the customer experience in international money transfer. The funds are directly
            transferred in real time to the recipients’ “Mobile Money” accounts, regardless of their mobile operator and
            throughout the territory, including rural areas.
        </p>
    </div>
    <div class="modal-footer learn-more">
        <a href="https://bizao.com/en/money-transfer/" target="_blank" class="modal-close"><u>Learn more about Money transfer</u></a>
    </div>
</div>
