<!-- is shown only when selecting Generate OTP -->
<div class="row">
  <div class="col s12 m6 l6 ">
      <p class="section-subtitle ">API</p>
      <hr style="border-color: #E3E8EE; border-style: solid;">
      <ul class="bar">
          <li><a routerLink="#" [ngClass]="{'is-active' : key === 'debitCard'}" (click)="changeTodebitCard()">DebitCard
                  API</a></li>
          <li><a routerLink="#" [ngClass]="{'is-active' : key === 'getStatVMapi'}" (click)="changeTogetStatVMapi()">GetStatus
                  API</a></li>
      </ul>
  </div>
  <div class="col s12 m6 l6 ">
      <p class="section-subtitle">Description</p>
      <hr style="border-color: #E3E8EE; border-style: solid;">
      <p *ngIf="key === 'debitCard' ">This API allows you to create payment transaction on Bizao Hub based on the information provided in your request.
        This API manages three categories of parameters: </p>
      <p *ngIf="key === 'getStatVMapi' "> Bizao &#60;getstatus&#62; is a one-time API </p>
  </div>
</div>
<hr style="border-color: #E3E8EE; border-style: solid;">

<div *ngIf=" key === 'debitCard' ">
<app-debit-card-api> </app-debit-card-api>
</div>
<div *ngIf=" key === 'getStatVMapi' ">
  <app-get-status-visa-mastercard></app-get-status-visa-mastercard>
</div>


