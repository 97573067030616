import { Component, OnInit } from '@angular/core';
import {FetchDataService} from '../../services/fetch-data.service';
declare var jQuery: any;

@Component({
  selector: 'app-get-access-token',
  templateUrl: './get-access-token.component.html',
  styleUrls: ['./get-access-token.component.css']
})
export class GetAccessTokenComponent implements OnInit {

  constructor(private fetchDataService : FetchDataService) { }
  switchToGettingStarted(){
    this.fetchDataService.tab='start';
  }
  ngOnInit(): void {

    (function ($) {
      
       $(document).ready(function(){
        $('.scrollspy').scrollSpy();
        if($(window).width() == 1024) {
          $('.row .table-of-contents').pushpin({ top: 80 });
        }
        else {
          $('.row .table-of-contents').pushpin({ top: 80 });
        }
        
      }); 
    })(jQuery);
  }

}


