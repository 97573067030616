<div class="row">
  <div class="col s12 m10 l10">

    <!-- Start of first wrapper -->


    <div class="first-wrapper section scrollspy" id="generate-challenge">

      <br>
      <!-- Start of Principles Section -->
      <p class="first-wrapper-title">1.Service Providers access to Bizao APIs</p>
      <p>
        Bizao gateway gives you access to a large panel of services through REST APIs.
DCB API is one of these services.
        <br>

        Our DCB API is available in two mode:<br>
   <div class="sidestepA">1.<b>DCB Onetime API : </b> which is live in most African countries with Orange and let you bill
your Client in onetime. With this API you need to manage the subscription system in
your side. (<b>Note</b> : This API will be deprecated per MNO where the Subscription API is
live in Bizao Store. )
   </div> <br>
   <div class="sidestepA">2.<b>DCB Subscription API</b> <br>
    This API is available right now only in Ivory-Coast for MTN
    operator. this API will manage the user subscription and user notification in Bizao
    side. Also it will manage the subscription renewal according to the Service
    frequency/periodicity. This API is Using Implicite mode (HE) to authenticate the user in case of MTN
   </div>
<br>

   <p>
    To get access to this service/API, the Bizao integration team will signUP you on the Hub and
create you a dedicated application(credential) for your DCB Subscription-traffic.

   </p>

   <p>
    In the authentication journey, the Bizao platform will generate/provide you a pair of keys
as credentials for each Client authenticated:

   </p>


   <p>
    By the way Bizao integration team will provide you the required/dedicated Bizao
    Credentials/IDs below to let you start traffic:
   </p>
   <br>

   <div class="sidestepA"><b>a. Access-Token : </b> the OAuthv2 access token related to your Bizao Account (
    up to you to manage the renewal of this Token (<u> duration of this Token is
    one year </u>)
       </div> <br>
    <div class="sidestepA"><b>b. ServiceID : </b> your unique ID that Bizao will provide you. No duration
      limitation on this ID.
           </div> <br>

 <div class="sidestepA"><b>c. ProductID : </b> the unique Id per product/content you will launch in Bizao
  Hub. Bizao will provide you this ID to use on all your DCB traffic query
  toward Bizao Hub. No duration limitation on this ID.
           </div> <br>
           <div class="sidestepA"><b>d. SP-ID : </b> your unique ID per country, that Bizao will provide you. No duration
            limitation on this ID.
                     </div> <br>


                     <p class="first-wrapper-title"> Data provisioning per partner</p>

                     <p>For each new partner we manage some provisioning data that we need to collect in the
                      onboarding step.</p>
                      <p>Below a list of main information, we collect:</p>

                      <p style="margin-left: 5%;">

                      <b>  • Country:</b> the partner will precise on which country he wonts to be onboarded <br>
                        <b>  • Mno: </b> the partner will precise on which Operator/MNO he wonts to be onboarded <br>
                          <b> • Product-id: </b> Bizao will provide this ID to the Partner <br>
                            <b> • Service-id: </b> Bizao will provide this ID to the Partner <br>
                              <b>• Service-name (OnbehalefOf): </b> the partner will precise the name of his service <br>
                                <b>   • Service-Pricing: </b> the partner will precise the pricing of his service <br>
                                  <b>  • Service-periodicity: </b> the partner will precise the periodicity of his service if its (daily, weekly,…) <br>
                                    <b>  • Service-description: </b> the partner will give a short description of his service(this wording will be
                        set in Bizao side just for information) <br>
                        </p>

                        <b> • Sms-notif-wording:</b><br>
<p style="margin-left: 10%;"> o <b> SMS-Sub-Wording: </b> the partner will provide the wording he wants as content of SMS
that will be sent to the EndUser once Susbcription processed by the BackEnd  <br>
o <b>SMS-UnSub-Wording: </b> the partner will provide the wording he wants as content of SMS
that will be sent to the EndUser once UnSusbcription processed by the Backend <br>
o <b>SMS-Renewal-Wording: </b> the partner will provide the wording he wants as content of
SMS that will be sent to the EndUser once the renewal processed by the Backend <br>
</p>
<p style="margin-left: 5%;">
<b>• ShortCode: </b>Bizao will provide this Short code to the Partner to use by endUser for
UnSubscription usecase using a dedicated Keyword <br>
<b>• MO-STOP-Keyword: </b> the partner will provide the “keyword” he wants for UnSubscription via
Bizao ShortCode : SMS-MO “STOP Keyword” to the ShortCode <br>
<b>• Smartcharging: </b> the partner will precise if he wants that Bizao activates the Smartcharging or
not <br>
<b>• SP-Callback-URL: </b>the partner will provide an URL where Bizao can Notify his backEnd ( B2BNotification). The URL will follow the format : “https//< PartnerDNS>/…/xxx< country-code >-
< mno-name >.php <br>

                      </p>


   <br> <br> <br>


<p class="first-wrapper-title">

2.  Bizao ACCESS_TOKEN
</p>

<p>As for the DCB onetime API, an ACCESS_TOKEN is generated thanks to your CLIENT_ID and
  CLIENT_SECRET that Bizao will sent you</p>

  <p>This Access-Token is required as header for all the DCB subscription API call you will make
    toward Bizao Hub.</p>

    <p><b>Note: </b></p>

    <p style="margin-left: 5%;">
      • You have to manage the renewal of this Access-Token based on this api <br>
      <a href="“https://api.bizao.com/token">“https://api.bizao.com/token</a>

        <br>
      <b> Sample of ACCESS_TOKEN:</b>
     <em>“4qa1bae4-3f9b-346-9t8b-c0e4d4ef”</em>
    </p>

    <p>
      For more detail on how to manage/generate this access-token please see detail in URL below :

    </p>
<a href="https://dev.bizao.com/docs/getting-started/generate-a-bizao-access_token/">https://dev.bizao.com/docs/getting-started/generate-a-bizao-access_token/</a>
<br> <br>

<p><b> 2.1 Service Provider data provisioning </b></p>

<p>
  In signUp step on Bizao Hub for the DCB-Subscription, you need to provide the below
information to be provisioned in the Bizao BackEnd.
</p>

<div style="margin-left: 5%;">


<p> <b>1. Product URL:</b>   the URL of the product/Service you Launch on Bizao Hub.</p>
<p> <b> 2. Product Price:</b> the price of you Content/Product</p>
<p><b>3. Periodicity of your product subscription:</b>  indicate if your Product is for a daily, weekly
  or monthly subscription.</p>
<p><b> 4. Product CallBack URL: </b>the Callback for the B2B notification related to your Product
    (you can indicate one CallBack per Product or use the same for all Product you will
    launch on Bizao Hub).</p>

    <p>
     <b>5. Wording of B2C SMS Notification:</b>  as subscription API will notify the EndUSers after
each subscription attempt, Bizao need to agree with the SP the wording to use for
each type of notification. Idem for the UnSubscription and renewal SMS-Notification
    </p>

  </div>


</div>
</div>
</div>

