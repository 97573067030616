import { Component, OnInit } from '@angular/core';
import { FetchDataService } from 'src/app/services/fetch-data.service';
import { environment } from 'src/environments/environment';
declare var jQuery: any;

@Component({
  selector: 'app-mobile-money-payment-api',
  templateUrl: './mobile-money-payment-api.component.html',
  styleUrls: ['./mobile-money-payment-api.component.css']
})
export class MobileMoneyPaymentAPIComponent implements OnInit {
  req_headers:any=null;
  req_body: any = null;
  res_headers:any=null;
  res_body: any = null;
  pulse: boolean = true;
  res_header_active:boolean=false;



  constructor(private fetchDataService: FetchDataService) {

    this.req_headers={
      "authorization": "Bearer 12345dxj-zd56-323c-85e9-d5v8h3s5b0",
      "country-code": "sn",
      "mno-name": "orange",
      "lang": "fr",
      "channel" : "web",
      "Content-Type":"application/json"

    }

    this.req_body={
      "currency": "XOF",
      "order_id": "Merchant_Order_id_4",
      "amount": 10,
      "state": "param1%3Dvalue1%26param2%3Dvalue2",
      "return_url": "Your-return_url",
      "cancel_url": "Your-cancel_url",
      "reference": "Your-reference"
    }
   }

  sendRequest() {

this.res_headers="There are no headers in the response"

this.res_body=
  {
    "order_id": "order_43048308FDJKFJDKF49854",
    "status": 201,
    "pay_token": "v1sqjbl4cahdoonenvedipechroz9yz1agvhxoxblnvyl2kngd4dcua4qyyma2kk",
    "payment_url": "https://webpayment.orange-money.com/ci/mpayment/abstract/v1sqjbl4cahdoonenvedipechroz9yz1agvhxoxblnvyl2kngd4dcua4qyyma2kk",
    "notif_token": "ibcukkpd2mgmahli4qgpig0w0hmhwbgi",
    "state": "param1%3Dvalue1%26param2%3Dvalue2"
}




    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {

        console.log(response[0])
        if(response[0].doc.response.success.header.length == 0) {
          this.res_headers= "There are no headers in the response";
          this.res_body = response[0].doc.response.success.body;
        }
        else if(response[0].doc.response.success.body.length == 0) {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = "There is no body in the response";
        }
        else {
          this.res_headers= response[0].doc.response.success.header;
          this.res_body = response[0].doc.response.success.body;
        }
      }
    );
    */
    this.res_header_active = true
  }

  togglePulse() {
    this.pulse = false;
  }

  ngOnInit(): void {
    (function ($) {
      $(document).ready(function () {
        $('.tabs').tabs();
      });

      $(document).ready(function () {
        $('.scrollspy').scrollSpy();
      });
    })(jQuery);



  }

  ngAfterViewInit() {
    /*
    this.fetchDataService.generate_challenge_otp(environment.ACCESS_TOKEN).subscribe(
      response => {
        console.log(response[0])
        this.req_headers=response[0].doc.request.header;
        this.req_body=response[0].doc.request.body;
      }
    );
    */
  }


}

