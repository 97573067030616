import { Component, OnInit } from '@angular/core';
import {FetchDataService} from '../../services/fetch-data.service';
declare var jQuery: any;
@Component({
  selector: 'app-get-status-api',
  templateUrl: './get-status-api.component.html',
  styleUrls: ['./get-status-api.component.css']
})
export class GetStatusApiComponent implements OnInit {

  constructor(private fetchDataService : FetchDataService) { }

  switchToMobileMoneyGetStatus(){
    this.fetchDataService.tab='mm';
    this.fetchDataService.mm_key = 'check-mm-pay-status';
  }
  ngOnInit(): void {

    (function ($) {
      
      $(document).ready(function(){
       $('.scrollspy').scrollSpy();
       $('.row .table-of-contents').pushpin({ top: 80 });
     }); 
   })(jQuery);
    
  }

}
