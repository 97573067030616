import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FetchDataService } from 'src/app/services/fetch-data.service';
declare var jQuery: any;

@Component({
  selector: 'app-bulk-get-status-api',
  templateUrl: './bulk-get-status-api.component.html',
  styleUrls: ['./bulk-get-status-api.component.css']
})
export class BulkGetStatusApiComponent implements OnInit {
  constructor(private router : Router, private fetchDataService: FetchDataService) { }

  switchToGenOTP(){
    this.fetchDataService.tab='dcb';
    this.fetchDataService.dcb_key = 'genOTP';
  }
  switchToValOTP(){
    this.fetchDataService.tab='dcb';
    this.fetchDataService.dcb_key = 'valOTP';
  }



  ngOnInit(): void {

    (function ($) {

      $(document).ready(function(){
       $('.scrollspy').scrollSpy();
       $('.row .table-of-contents').pushpin({ top: 80 });
     });
   })(jQuery);
   console.log("hum")
  }



}

