<div class="row">
    <div class="col s12 m10 l10">
        <!-- Start of first wrapper -->

        <div class="first-wrapper section scrollspy" id="principles">
            <p class="first-wrapper-title">
                SMS MT Notification API </p>
            <br>
            <p class="first-wrapper-title">1. Principles</p>
            <p>Bizao provides you with a free SMS-Bundle to let you notify your end user using the SMS API.
                To notify the end_user, youfirst need to generate the user’s bizao-token and bizao-alias
                using one of three authentication mechanims described previously.</p>
            <br>
            <p class="first-wrapper-title">2. Description</p>
            <p>To notify your end-user by SMS, you will use :</p>
            &#x2022; <span class="keys">BIZAO-URL</span> : <a href="#">smsmessaging/v1/outbound/tel%3A%2Bxxxxx/requests</a> <br><br>
            &#x2022; <span class="keys">BIZAO-HOST</span> :<a>api.bizao.com</a>
<br>
            <i>where : <u>“tel%3A%2Bxxxxx”</u> is your sender-address in url encode format.</i> &nbsp;
            <b>Sample</b> : <span class="code"><code>“tel%3A%2B2250000”</code></span> <br><br>
            <p>The query will contain the Headers set to the specified value below :</p>

            <table>
                <tr>
                    <td><b>Header</b> </td>
                    <td><b>Description/Content</b></td>
                </tr>
                <tr>
                    <td>X-OAPI-Application-Id</td>
                    <td>always use: <b>BIZAO</b></td>
                </tr>
                <tr>
                    <td>X-OAPI-Contact-Id </td>
                    <td>always use: <b>b2b-bizao-97b5878</b>
                    </td>
                </tr>
                <tr>
                    <td>X-OAPI-Resource-Type </td>
                    <td>always use: <b>SMS_OSM</b>
                    </td>
                </tr>
                <tr>
                    <td>bizao-alias </td>
                    <td><span class="light-span">BIZAO_ALIAS</span> generated using one of the authentication
                        method above (ise2 in this current version of
                        authentication)</td>
                </tr>
                <tr>
                    <td>bizao-token </td>
                    <td><span class="light-span">BIZAO_TOKEN</span> generated using one of the
                        authentication method above. (OrangeApiToken in this
                        current version of authentication)</td>
                </tr>
                <tr>
                    <td>X-Orange-MCO</td>
                    <td>Oxx , where xx is an alpha2 country code.
                        You can use this site to got this code: <br>
                        <code class="code">https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2</code></td>
                </tr>
                <tr>
                    <td>Authorization</td>
                    <td><span class="light-span">YOUR_ACCESS_TOKEN</span></td>
                </tr>
            </table>
            <br><br>
            <p>The body of your query will contain the parameters below :</p>
            <table>
                <tr>
                    <td><b>Parameter</b> </td>
                    <td><b>Description</b></td>
                    <td><b>Usage</b></td>
                </tr>
                <tr>
                    <td>address</td>
                    <td>Recipient user ID is a key in the header
                        which contains the end user alias to use. <br>
                        Authorized values are:
                        <code>acr:X-Orange-ISE2 for the header
                                        X-Orange-ISE2</code></td>
                    <td>Mandatory, unique
                    </td>
                </tr>
                <tr>
                    <td>message</td>
                    <td>String, must be URL-escaped as per RFC
                        1738 and not longer than 160 characters.</td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>senderAddress (*)</td>
                    <td>String, See  <b>5.3 Recap of API-SMS parameter</b> for values</td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>SenderName</td>
                    <td>Firendly name displayed on end user device ( size should not exceed 11 characters)</td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>clientCorrelator</td>
                    <td>String, Uniquely identifies this created SMS
                        request. This will be useful in communication
                        String, <br> failures while sending the request. By
                        using the same clientCorrelator when
                        retrying the request <code>WSO2.TELCO HUB</code> avoids
                        sending the same SMS again.</td>
                    <td>Mandatory</td>
                </tr>
                <tr>
                    <td>receiptRequest</td>
                    <td>notifyURL: String, The URL to which you would
                        like a notification of delivery sent. The format
                        of this notification is shown below. If
                        notifications are required the notifyURL
                        parameter will be sent within the
                        receiptRequest element</td>
                    <td>Optional</td>
                </tr>
                <tr>
                    <td>callbackData</td>
                    <td>allbackData: Will be passed back in this
                        notification. It’s used to identify the message
                        the receipt relates to (or any other useful
                        data, such as a function name). This is only
                        valid if notifications are required – sent with
                        the notifyURL parameter within the
                        receiptRequest element</td>
                    <td>Optional</td>
                </tr>
            </table>

        </div>
        <!-- End of first wrapper -->

        <!-- Start of second wrapper -->
        <div class="second-wrapper section scrollspy" id="samples-errors">
            <p class="first-wrapper-title">SMS Notification query sample:</p>

            <p> <b>Note</b>: For more detail you can see the swagger file: <i>“BIZAO-SMS_V1_swagger.yaml”</i>
            </p>
            <div class="sample-wrapper">
                <span class="light-span">Request sample :</span>
            <pre>
            <div class="code">
                curl -X POST \
                https://api.bizao.com/smsmessaging/v1/outbound/tel%3A%2B2250000/requests \
                -H 'authorization: Bearer YOUR_ACCESS_TOKEN' \
                -H 'cache-control: no-cache' \
                -H 'content-type: application/json' \
                -H 'bizao-token: BIZAO_TOKEN' \
                -H 'x-oapi-application-id: BIZAO' \
                -H 'x-oapi-contact-id: b2b-bizao-97b5878' \
                -H 'x-oapi-resource-type: SMS_OSM' \
                -H 'bizao-alias: BIZAO_ALIAS' \
                -H 'x-orange-mco: OCI' \
                -d ' &#123;
                "outboundSMSMessageRequest":  &#123;
                "address": ["acr:X-Orange-ISE2"],
                "senderAddress": "tel:+2250000",
                "outboundSMSTextMessage":  &#123;
                "message": "Welcome SMS"
                 &#125;,
                "clientCorrelator": "MyCorrealtorID",
                "receiptRequest":  &#123;
                "notifyURL": "http://application.example.com/notifications/DeliveryInfoNotification",
                "callbackData": "some-data-useful-to-the-requester"
                 &#125;,
                "senderName": "NameToDisplay"
                 &#125;
                 &#125;
  </div>
  </pre>
            <br><br>
            <p><b>Note :</b> For each new <u><b><i>SMS-Query</i></b></u> you have to provide a new
                <b><i>“clientCorrelator” </i></b> value SMS</p><br>

            <span class="light-span">Success response sample:</span>
            <pre>
            <div class="code">
&#123;
    "outboundSMSMessageRequest": &#123;
        "address": [
            "acr:X-Orange-ISE2"
        ],
        "senderAddress": "tel:+2210000",
        "senderName": "BIZAO",
        "receiptRequest": &#123;
        "notifyURL": "http://application.example.com/notifications/DeliveryInfoNotification",
        "callbackData": "some-data-useful-to-the-requester"
        &#125;,
        "outboundSMSTextMessage": &#123;
        "message": "Votre abonnement a réussi."
        &#125;,
        "clientCorrelator": "MyCorrealtorID",
        "resourceURL": "https://gateway1a.mife.sla-mobile.com.my:8243/smsmessaging/outbound/tel%3A%2B2210000/requests/1529584291923SM8202/ deliveryInfos"
    &#125;
&#125;
            </div>
            </pre>
        </div>
            <br>
            <p class="first-wrapper-title">3. Recap of API-SMS parameter value by authorized country</p>
            <p>Below a recap of specific API-SMS parameter value by authorized country.</p>
            <br>
            <table>
                <tr>
                    <td><b>Country</b></td>
                    <td><b>MCO</b></td>
                    <td><b>senderAddress</b></td>
                </tr>
                <tr>
                    <td>Ivory-
                        Coast</td>
                    <td>OCI</td>
                    <td>tel:+2250000</td>
                </tr>
                <tr>
                    <td>RDCongo</td>
                    <td>ODC</td>
                    <td>tel:+2430000</td>
                </tr>
                <tr>
                    <td>Cameroon</td>
                    <td>OCM</td>
                    <td>tel:+2370000</td>
                </tr>
                <tr>
                    <td>Senegal</td>
                    <td>OSN</td>
                    <td>tel:+2210000</td>
                </tr>
                <tr>
                    <td>Tunisia</td>
                    <td>OSN</td>
                    <td>tel:+2160000</td>
                </tr>
                <tr>
                    <td>Burkina
                        faso</td>
                    <td>OBF</td>
                    <td>Tel:+2260000</td>
                </tr>
            </table>
            <br>
            <p class="first-wrapper-title">4. Notification error codes</p>
            <table>
                <tr>
                    <td>SVC0280</td>
                    <td>Message too long.
                        Maximum length is
                        %1 characters</td>
                    <td>%1 – number of characters
                        allowed in a message</td>
                    <td>400 Bad request</td>
                </tr>
                <tr>
                    <td>900908</td>
                    <td>Resource forbidden</td>
                    <td>none</td>
                    <td>403 Forbidden</td>
                </tr>

            </table>


            <p class="first-wrapper-title">SMS API internal error codes</p>

            <table style="margin-bottom: -5%;">
              <tr>
              <th style="background-color: darkseagreen; ">Internal</th>
              <th style="background-color: darkseagreen;">Output mapping</th>
              </tr>
              <tr>
               <th></th>
              </tr>
            </table>


            <table>
              <tr>
                <th>Error code</th>
                <th>Description</th>
                <th>Error code</th>
                <th>Error Variables</th>
              </tr>

              <tr>
                <td>PDK_SMSW_0001</td>
                <td>nternal error</td>
                <td>SVC0001</td>
                <td>%1: PDK_SMSW_0001</td>
              </tr>
              <tr>
                <td>PDK_SMSW_0002</td>
                <td>Integration error</td>
                <td>SVC0001</td>
                <td>%1: PDK_SMSW_0002</td>
              </tr>
              <tr>
                <td>PDK_SMSW_0003</td>
                <td>Unavailable country</td>
                <td>SVC0004</td>
                <td>%1: senderAddress value</td>
              </tr>
              <tr>
                <td>PDK_SMSW_0004</td>
                <td>Duplicate client correlator</td>
                <td>SVC0004</td>
                <td>%1: clientCorrelator value <br>
                  %2: TBD</td>
              </tr>
              <tr>
                <td>PDK_SMSW_0005</td>
                <td>Missing input value</td>
                <td>SVC0002</td>
                <td>%1: missing value</td>
              </tr>
              <tr>
                <td>PDK_SMSW_0006</td>
                <td>Invalid sender address</td>
                <td>SVC0004</td>
                <td>%1: senderAddress value</td>
              </tr>
              <tr>
                <td>PDK_SMSW_0007</td>
                <td>Invalid recipient address</td>
                <td>SVC0004</td>
                <td>%1: address value</td>
              </tr>
              <tr>
                <td>PDK_SMSW_0008</td>
                <td>Message too long</td>
                <td>SVC0280</td>
                <td>%1: max message length</td>
              </tr>
              <tr>
                <td>PDK_SMSW_0009</td>
                <td>Invalid input value</td>
                <td>SVC0002</td>
                <td>%1: invalid value</td>
              </tr>
              <tr>
                <td>PDK_SMSW_0011</td>
                <td>BE-API is unresponsive</td>
                <td>SVC0001</td>
                <td>%1: PDK_SMSW_0011</td>
              </tr>
              <tr>
                <td>PDK_SMSW_0013</td>
                <td>SDP GOS is unresponsive</td>
                <td>SVC0001</td>
                <td>%1: PDK_SMSW_0013</td>
              </tr>

              <tr>
                <td>PDK_SMSW_1000</td>
                <td>BE-API internal error</td>
                <td>SVC0001</td>
                <td>%1: PDK_SMSW_1000</td>
              </tr>

              <tr>
                <td>PDK_SMSW_1001</td>
                <td>Resource consumption failure: not authorized [%1]</td>
                <td>POL0001</td>
                <td>%1: Not enough credit</td>
              </tr>

              <tr>
                <td>PDK_SMSW_1002</td>
                <td>Resource consumption failure: invalid parameter</td>
                <td>POL0001</td>
                <td>%1: No contract found for the given country / service / contact</td>
              </tr>

              <tr>
                <td>PDK_SMSW_1003</td>
                <td>Resource consumption failure: not authorized [%1]</td>
                <td>POL0001</td>
                <td>%1: Expired contract</td>
              </tr>

              <tr>
                <td>PDK_SMSW_1004</td>
                <td>Resource consumption failure: unknown transaction
                  ID
                  </td>
                <td>SVC0001</td>
                <td>%1: PDK_SMSW_1004</td>
              </tr>

              <tr>
                <td>PDK_SMSW_3000</td>
                <td>SDP GOS internal error
                  </td>
                <td>SVC0001</td>
                <td>%1: PDK_SMSW_3000</td>
              </tr>

              <tr>
                <td>PDK_SMSW_3001</td>
                <td>SDP GOS SLA error
                  </td>
                <td>SVC0001</td>
                <td>%1: PDK_SMSW_3001
k                </td>
              </tr>

            </table>



        </div>
        <!-- End of second wrapper -->



    </div>



    <div class="col hide-on-small-only s12 m2 l2">
        <ul class="section table-of-contents">
            <li>
                <p class="toc-title">On this page</p>
            </li>
            <li><a href="#principles">Principles</a></li>
            <li><a href="#samples-errors">Samples & errors</a></li>
        </ul>
    </div>




</div>
