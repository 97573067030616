<!-- is shown only when selecting Generate OTP -->
<div class="row">
  <div class="col s12 m6 l6 ">
      <p class="section-subtitle ">API</p>
      <hr style="border-color: #E3E8EE; border-style: solid;">
      <ul class="bar">
          <li><a routerLink="#" [ngClass]="{'is-active' : key === 'payapi'}" (click)="changeToPayAPI()">Payment
                  API</a></li>
          <li><a routerLink="#" [ngClass]="{'is-active' : key === 'getstatapi'}" (click)="changeToGetStatAPI()">GetStatus
                  API</a></li>
      </ul>
  </div>
  <div class="col s12 m6 l6 ">
      <p class="section-subtitle">Description</p>
      <hr style="border-color: #E3E8EE; border-style: solid;">
      <p *ngIf="key === 'payapi' ">Bizao ‹mobilemoney› API is a one-time API. Below the syntax and description . </p>
      <p *ngIf="key === 'getstatapi' ">Bizao getStatus is a one-time API.  </p>
  </div>
</div>
<hr style="border-color: #E3E8EE; border-style: solid;">

<div *ngIf=" key === 'payapi' ">
<app-mobile-money-payment-api> </app-mobile-money-payment-api>
</div>
<div *ngIf=" key === 'getstatapi' ">
  <app-mobile-money-get-status-api></app-mobile-money-get-status-api>
</div>

