import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
   // materializecss modal controller
   (function ($) {
    $(document).ready(function(){
      $('.modal').modal();
    });
  })(jQuery);
  }

}
