<div class="container">
    <div class="row">
        <div class="col m3 l3 sidenavigation lower_screen hide-on-small-only ">
            <app-left-side-navigation></app-left-side-navigation>
        </div>
        <div class="col s12 m9 l9">
            <app-content-container></app-content-container>
        </div>
    </div>
    
</div>