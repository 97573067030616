import { Component, OnInit } from '@angular/core';
import {FetchDataService} from '../../services/fetch-data.service';
declare var jQuery: any;

@Component({
  selector: 'app-mobile-money-payment-apiS',
  templateUrl: './mobile-money-payment-api.component.html',
  styleUrls: ['./mobile-money-payment-api.component.css']
})
export class MobileMoneyPaymentApiComponent implements OnInit {

  constructor(private fetchDataService : FetchDataService) { }

  switchToMobileMoneyPayment(){
    this.fetchDataService.tab='mm';
    this.fetchDataService.mm_key = 'mm-pay-api';
  }
  ngOnInit(): void {

    (function ($) {

      $(document).ready(function(){
       $('.scrollspy').scrollSpy();
       $('.row .table-of-contents').pushpin({ top: 80 });
     });
   })(jQuery);

  }

}
