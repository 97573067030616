import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FetchDataService} from '../../services/fetch-data.service';

declare var jQuery: any;
@Component({
  selector: 'app-otp-authentication',
  templateUrl: './otp-authentication.component.html',
  styleUrls: ['./otp-authentication.component.css']
})
export class OtpAuthenticationComponent implements OnInit {

  constructor(private router : Router, private fetchDataService: FetchDataService) { }

  switchToGenOTP(){
    this.fetchDataService.tab='dcb';
    this.fetchDataService.dcb_key = 'genOTP';
  }
  switchToValOTP(){
    this.fetchDataService.tab='dcb';
    this.fetchDataService.dcb_key = 'valOTP';
  }



  ngOnInit(): void {

    (function ($) {
      
      $(document).ready(function(){
       $('.scrollspy').scrollSpy();
       $('.row .table-of-contents').pushpin({ top: 80 });
     }); 
   })(jQuery);
  }

 
 
}
