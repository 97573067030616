import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.css']
})
export class MainNavigationComponent implements OnInit {
  screenRatio:any;
  screenWidth = 10000;
  constructor() { }


  ngOnInit(): void {

    (function ($) {
      $(document).ready(function(){
      this.screenWidth = $(window).width();
      console.log(this.screenWidth);
     }
      )
    })(jQuery);
    this.screenRatio = window.devicePixelRatio;
    console.log(this.screenRatio);
    (function ($) {
      $('.collapsible').collapsible();
    })(jQuery);
    (function ($) {
      $('.sidenav').sidenav();
    })(jQuery);

  if($(window).width() > 1000) {
    (function ($) {
      $('#section_wrapper').addClass('navbar-fixed');
      $(document).ready(function(){
        $(window).scroll(function(){
          if($(window).scrollTop()>100) {
             $('nav').removeClass('main_nav_before_scroll_line');
             $('nav').removeClass('main_nav_before_scroll_shadow');
             $('nav').addClass('main_nav_after_scroll_shadow');
           }
           else if($(window).scrollTop() < 100) {
            $('nav').addClass('main_nav_before_scroll_line');
            $('nav').addClass('main_nav_before_scroll_shadow');
            $('nav').removeClass('main_nav_after_scroll_shadow');
          }

          })
     }
      )
    })(jQuery);
  }

  }

}
