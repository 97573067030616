import { Component, OnInit } from '@angular/core';
import { FetchDataService } from '../../services/fetch-data.service';
declare var jQuery: any;

@Component({
  selector: 'app-api-testor',
  templateUrl: './api-testor.component.html',
  styleUrls: ['./api-testor.component.css']
})
export class ApiTestorComponent implements OnInit {

  ismpclicked=false
  isbpclicked=false
  ismtclicked=false

  constructor(private fetchDataService: FetchDataService) { }
  tab = this.fetchDataService.tab;
  toggleToStart() {
    this.ismpclicked=false
    this.isbpclicked=false

    this.tab = 'start'
  }
  toggleToDCB() {
    this.ismpclicked=false
    this.isbpclicked=false

    this.tab = 'dcb'
  }
  toggleToMM() {
    this.ismpclicked=false
    this.isbpclicked=false

    this.tab = 'mm'
  }

  toggleToMP()
  {
    this.ismpclicked=true
    this.isbpclicked=false
    this.tab='mp'
    console.log(this.ismpclicked)
  }
  toggletoBP()
  {
    this.ismpclicked=false
    this.isbpclicked=true
    this.ismtclicked=false
    this.tab='bp'


  }
  toggleToMMAPI()
  {
    this.ismpclicked=true
    this.isbpclicked=false
    this.tab='mmapi'
  }

  toggletoBPQ()
  {
    this.ismpclicked=false
    this.isbpclicked=true
    this.ismtclicked=false
    this.tab='bpapi'
  }

  toggleToVMAPI()
  {
    this.ismpclicked=true
    this.isbpclicked=false
    this.tab='vmapi'
  }

  toggleToMTAPI()
  {
    this.ismpclicked=false
    this.isbpclicked=false
    this.ismtclicked=true
    this.tab='mtapi'
  }




  ngOnInit(): void {

    //Initialize materialiaze tabs
    (function ($) {

      $(document).ready(function () {
        $('.tabs').tabs();
      });
    })(jQuery);
    //Initialize materialiaze tabs

  }

}
